import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Input,
  useToast,
  VStack,
} from '@chakra-ui/react';
import axios from '../../../config/axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalStoreContext } from '../../../context';

const CreateGuest = () => {
  const { dispatch } = useGlobalStoreContext();
  const { handleSubmit, register } = useForm();
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'create guest',
        description: 'create event guest',
      },
    });
  }, [dispatch]);

  const onSubmit = async (data: any) => {
    let formData = new FormData();
    for (const [key, value] of Object.entries<any>(data)) {
      if (typeof value === 'object') {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    }
    try {
      setLoading(true);
      await axios.post('/event/special-guest/create', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      toast({
        status: 'success',
        title: 'guest created',
      });
      navigate('/guests');
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'failed to create guest',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/guests">
            Guests
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Create
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="stageName" isRequired>
              <FormLabel>Stage Name</FormLabel>
              <Input
                {...register('stageName')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
            <FormControl id="website" isRequired>
              <FormLabel>Website</FormLabel>
              <Input
                {...register('website')}
                variant="filled"
                borderRadius="lg"
                type="url"
                required
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="firstName" isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                {...register('firstName')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
            <FormControl id="lastName" isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                {...register('lastName')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
          </Stack>
          <FormControl id="website" isRequired>
            <FormLabel>Logo</FormLabel>
            <Input
              {...register('image')}
              variant="filled"
              type="file"
              accept="image/*"
              borderRadius="lg"
              required
            />
          </FormControl>
          <Button
            isLoading={loading}
            type="submit"
            variant="gradient"
            alignSelf="flex-end"
          >
            Create Guest
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default CreateGuest;
