import {
  Heading,
  VStack,
  chakra,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputRightElement,
  InputGroup,
  useDisclosure,
  IconButton,
  Button,
  useToast,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { MERCHANT_URL } from '../config';
import axios from '../config/axios';
import { useGlobalStoreContext } from '../context';

const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useGlobalStoreContext();
  const { isOpen, onToggle } = useDisclosure({
    defaultIsOpen: false,
  });
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const resData = await axios.post('/auth/login-admin', data);
      const user = resData.data.data.user;
      dispatch({
        type: 'LOGIN',
        payload: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          email: user.email,
          isLoggedIn: true,
          isSocialAdmin: user.roles
            .map((el: any) => el.name)
            .includes('SOCIAL_MEDIA'),
          isAdmin: user.roles.map((el: any) => el.name).includes('ADMIN'),
          isTicketAdmin: user.roles
            .map((el: any) => el.name)
            .includes('TICKET_ADMIN'),
          isMallAdmin: user.roles
            .map((el: any) => el.name)
            .includes('MALL_ADMIN'),
          isSuperUser: user.roles
            .map((el: any) => el.name)
            .includes('SUPER_USER'),
        },
      });
      toast({
        title: 'Logged in successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      navigate('/');
    } catch (err) {
      toast({
        title: 'Login error',
        description: 'Are you an admin?',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      h={{
        base: '78vh',
        lg: '100vh',
      }}
      w="100%"
    >
      <Heading
        fontWeight="500"
        mb="4"
        fontSize={{
          base: '2xl',
          lg: '3xl',
        }}
      >
        JETRON
        <chakra.span color="brand.100" fontWeight="light">
          MALL
        </chakra.span>{' '}
        ADMIN
      </Heading>
      <Box
        w={{
          base: '80vw',
          lg: 'md',
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={5}>
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input
                {...register('email')}
                variant="filled"
                type="email"
                borderRadius="lg"
                required
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  {...register('password')}
                  variant="filled"
                  type={isOpen ? 'text' : 'password'}
                  borderRadius="lg"
                  required
                />
                <InputRightElement>
                  <IconButton
                    size="sm"
                    variant="ghost"
                    aria-label="hide/show password"
                    onClick={onToggle}
                    shadow="none"
                    icon={isOpen ? <FaEye /> : <FaEyeSlash />}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button
              isLoading={loading}
              type="submit"
              w="100%"
              variant="gradient"
            >
              Login
            </Button>
          </VStack>
        </form>
      </Box>
      <ChakraLink isExternal href={MERCHANT_URL}>
        Forgot your password?
      </ChakraLink>
    </VStack>
  );
};

export default Login;
