import data from "./data";

export const MERCHANT_URL = process.env.REACT_APP_MERCHANT_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const CONTEXT_KEY = 'adminJetronMallPersist';
export const colorThemeArray = ["blue", "cyan", "gray", "green", "orange", "pink", "purple", "red", "teal", "yellow", "whiteAlpha", "linkedin", "facebook", "messenger", "whatsapp", "twitter", "telegram"]
export const TOKEN_TYPES = ['user/email-confirmation',
  'auth/reset-password',
  'event/ticket-order',
  'store/email-confirmation',];
export const APP_DATA = data;
export const cloudinaryCloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
