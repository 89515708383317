import { initialState } from './index';

type Reducer = (state: State, action: Action) => State;

const reducer: Reducer = (state, action) => {
    switch (action.type) {
        case "LOGIN":
            return { ...state, user: { ...action.payload } };
        case "LOGOUT":
            return {
                ...state,
                user: initialState.user,
            };
        case "TOGGLE_SIDEBAR":
            return {
                ...state,
                sidebarActive: !state.sidebarActive,
            };
        case "SET_SIDEBAR":
            return {
                ...state,
                sidebarActive: action.payload,
            };
        case "SETUP_PAGE":
            return { ...state, pageData: { ...action.payload } };
        default:
            throw new Error(`Invalid type: ${action.type}`)
    }
};

export default reducer;
