import {
  Avatar,
  Badge,
  Box,
  HStack,
  Text,
  Link as ChakraLink,
  VStack,
  Icon,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useGlobalStoreContext } from '../../context';
interface LinkInterface {
  name: string;
  url: string;
  icon: IconType;
  links: {
    name: string;
    url: string;
  }[];
}

const Sidebar = ({ links }: { links: LinkInterface[] }) => {
  const location = useLocation();
  const { state, dispatch } = useGlobalStoreContext();

  const toggleSidebar = () => {
    dispatch({
      type: 'TOGGLE_SIDEBAR',
    });
  };
  return (
    <>
      {state.sidebarActive && (
        <Box
          position="fixed"
          onClick={toggleSidebar}
          boxSize="100%"
          zIndex="18"
          backgroundColor="blackAlpha.600"
          display={{
            base: 'block',
            lg: 'none',
          }}
        ></Box>
      )}
      <Box
        display={location.pathname === '/login' ? 'none' : 'block'}
        w={{ base: '80%', md: '30%', lg: '20%' }}
        backgroundColor="background.300"
        position={{
          base: 'fixed',
          lg: 'static',
        }}
        zIndex="20"
        h="inherit"
        p={3}
        overflowY="scroll"
        transition="left 200ms ease"
        style={{
          marginInlineStart: '0',
        }}
        left={state.sidebarActive ? '0' : '-100%'}
      >
        <HStack justifyContent="space-between" alignItems="start" p={1}>
          <Avatar
            bgColor="brand.100"
            name={`${state.user.firstName} ${state.user.lastName}`}
            src={`https://avatars.dicebear.com/api/adventurer/${state.user.firstName} ${state.user.lastName}.svg?background=%23232323`}
          />
          <Box w="75%">
            <Text
              textTransform="uppercase"
              fontSize="sm"
              fontWeight="bold"
              isTruncated
            >
              {state.user.firstName} {state.user.lastName}
            </Text>
            <Badge colorScheme="orange">
              {state.user.isSuperUser
                ? 'super user'
                : state.user.isAdmin
                ? 'general admin'
                : state.user.isTicketAdmin
                ? 'ticket admin'
                : state.user.isMallAdmin
                ? 'mall admin'
                : state.user.isSocialAdmin
                ? 'social admin'
                : 'normal user'}
            </Badge>
          </Box>
        </HStack>
        <VStack alignItems="start" spacing={8} mt={8}>
          {links.map((el) => (
            <Box key={el.name}>
              <HStack>
                <Icon as={el.icon} />
                <ChakraLink
                  textTransform="uppercase"
                  fontWeight="bold"
                  fontSize="md"
                  _hover={{
                    textDecor: 'none',
                  }}
                  to={el.url}
                  as={Link}
                >
                  {el.name}
                </ChakraLink>
              </HStack>
              <VStack alignItems="start" ml={5} mt={3}>
                {el.links.map((el) => (
                  <ChakraLink
                    textTransform="capitalize"
                    key={el.url}
                    as={Link}
                    to={el.url}
                    color={
                      location.pathname.startsWith(el.url)
                        ? 'brand.100'
                        : 'gray.300'
                    }
                    _hover={{
                      color: 'brand.100',
                    }}
                  >
                    {el.name}
                  </ChakraLink>
                ))}
              </VStack>
            </Box>
          ))}
        </VStack>
      </Box>
    </>
  );
};

export default Sidebar;
