import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Stack,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import axios from '../../../config/axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

const CreateTicket = ({
  event,
  addTicket,
}: {
  event?: string;
  addTicket: (data: Ticket) => void;
}) => {
  const { handleSubmit, register, reset } = useForm();
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      setLoading(true);
      const resData = await axios.post('/event/ticket/create', {
        ...data,
        event,
      });
      toast({
        status: 'success',
        title: 'ticket created',
      });
      addTicket({
        ...resData.data.data.ticket,
        id: resData.data.data.ticket.id,
      });
      reset();
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'failed to create ticket',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="name" isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
            <FormControl id="description" isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea
                {...register('description')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="price" isRequired>
              <FormLabel>Price</FormLabel>
              <Input
                {...register('price')}
                variant="filled"
                borderRadius="lg"
                type="number"
                required
              />
            </FormControl>
            <FormControl id="count" isRequired>
              <FormLabel>Quantity</FormLabel>
              <Input
                {...register('count')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="isDefault">
              <FormLabel>Is Default</FormLabel>
              <Switch colorScheme="brand" {...register('isDefault')} />
            </FormControl>
            <FormControl isRequired id="deadline">
              <FormLabel>Deadline</FormLabel>
              <Input
                {...register('deadline')}
                variant="filled"
                borderRadius="lg"
                type="datetime-local"
              />
            </FormControl>
          </Stack>
          <Button
            isLoading={loading}
            type="submit"
            variant="gradient"
            alignSelf="flex-end"
          >
            Create Ticket
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default CreateTicket;
