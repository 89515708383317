import { Alert, AlertDescription, AlertTitle, Button } from '@chakra-ui/react';

const DangerZone = ({ action, text }: { action: () => void; text: string }) => {
  return (
    <Alert
      flexDir="column"
      alignItems="start"
      gap="2"
      status="error"
      variant="top-accent"
    >
      <AlertTitle>Danger Zone</AlertTitle>
      <AlertDescription>
        Actions done here may not have the ability to be undone
      </AlertDescription>
      <Button alignSelf="flex-end" onClick={action} size="sm" colorScheme="red">
        {text}
      </Button>
    </Alert>
  );
};

export default DangerZone;
