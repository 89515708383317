import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Stack,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import axios from '../../../config/axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import useFetch from '../../../hooks/useFetch';
import Loader from '../../../components/loader';
import Error from '../../../components/error';
import { formatDefaultDate } from '../../../utils';

const EditTicket = ({
  ticket: tikt,
  onClose,
  editTicket,
}: {
  ticket: Ticket;
  onClose: () => void;
  editTicket: (ticket: Ticket) => void;
}) => {
  const { handleSubmit, register } = useForm();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [ticketLoading, ticketError, ticket] = useFetch(`{
    ticketById(id: "${tikt.id}") {
      name
      event {
        id
        name
      }
      price
      count
      isDefault
      deadline
      description
    }
  }`);

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const resData = await axios.put(`/event/ticket/update/${tikt.id}`, {
        ...data,
        event: tikt.event.id,
      });

      const newUpdatedTicket = {
        ...resData.data.data.ticket,
        id: tikt.id,
        price: Number(resData.data.data.ticket.price),
        count: Number(resData.data.data.ticket.count),
      };
      editTicket(newUpdatedTicket);
      toast({
        status: 'success',
        title: 'ticket updated',
      });
      onClose();
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'failed to update ticket',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setLoading(false);
    }
  };

  if (ticketLoading) return <Loader />;
  if (ticketError) return <Error />;
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="name" isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={ticket.ticketById.name}
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="price" isRequired>
              <FormLabel>Price</FormLabel>
              <Input
                {...register('price')}
                variant="filled"
                borderRadius="lg"
                type="number"
                required
                defaultValue={ticket.ticketById.price}
              />
            </FormControl>
            <FormControl id="count" isRequired>
              <FormLabel>Quantity</FormLabel>
              <Input
                {...register('count')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={ticket.ticketById.count}
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="isDefault">
              <FormLabel>Is Default</FormLabel>
              <Switch
                colorScheme="brand"
                {...register('isDefault')}
                defaultChecked={ticket.ticketById.isDefault}
              />
            </FormControl>
            <FormControl isRequired id="deadline">
              <FormLabel>Deadline</FormLabel>
              <Input
                {...register('deadline')}
                variant="filled"
                borderRadius="lg"
                type="datetime-local"
                defaultValue={formatDefaultDate(ticket.ticketById.deadline)}
              />
            </FormControl>
          </Stack>
          <FormControl id="description" isRequired>
            <FormLabel>Description</FormLabel>
            <Textarea
              {...register('description')}
              variant="filled"
              borderRadius="lg"
              required
              defaultValue={ticket.ticketById.description}
            />
          </FormControl>
          <Button
            isLoading={loading}
            type="submit"
            variant="gradient"
            alignSelf="flex-end"
          >
            Edit Ticket
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default EditTicket;
