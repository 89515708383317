import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { ChangeEventHandler, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGlobalStoreContext } from '../../context';
import { useForm } from 'react-hook-form';
import { TOKEN_TYPES } from '../../config';
import axios from '../../config/axios';
import useFetch from '../../hooks/useFetch';
import useSearchInput from '../../hooks/useSearchInput';

const GenerateToken = () => {
  const { dispatch } = useGlobalStoreContext();
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register } = useForm();
  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'generate token',
        description: 'create tokens of any type',
      },
    });
  }, [dispatch]);

  const [userValue, setUserValue] = useState('');
  const [userDefaultValue, setUserDefaultValue] = useState({});
  const [usersLoading, usersError, users] = useFetch(`{
    users(keyword: "${userValue}") {
      data {
        id
        firstName
        lastName
      }
    }
  }`);
  const onUserSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const query = e.target.value;
    setUserValue(query);
  };
  const { value: uservalue, component: UserSearch } = useSearchInput({
    label: 'User',
    elements: users ? users.users.data : [],
    error: usersError,
    isRequired: false,
    loading: usersLoading,
    placeholder: 'search for user',
    searchOnChange: onUserSearch,
    searchValue: userValue,
    searchValueKeys: ['firstName', 'lastName'],
    setSearchValue: (text) => setUserValue(text),
    defaultValue: userDefaultValue,
  });
  useEffect(() => {
    setUserDefaultValue({
      id: '',
      name: 'Select User',
    });
  }, []);

  const [storeValue, setStoreValue] = useState('');
  const [storesLoading, storesError, stores] = useFetch(`{
    stores(keyword: "${storeValue}") {
      data {
        id
        firstName
        lastName
      }
    }
  }`);
  const onStoreSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const query = e.target.value;
    setStoreValue(query);
  };
  const { value: storevalue, component: StoreSearch } = useSearchInput({
    label: 'Store',
    elements: stores,
    error: storesError,
    isRequired: false,
    loading: storesLoading,
    placeholder: 'search for stores',
    searchOnChange: onStoreSearch,
    searchValueKeys: ['name'],
    searchValue: storeValue,
    setSearchValue: (text) => setStoreValue(text),
    disabled: true,
    defaultValue: '',
  });
  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'edit token',
        description: 'edit token details e.g expires, user, store etc',
      },
    });
  }, [dispatch]);

  const onSubmit = async (data: any) => {
    let cred: any = { ...data, user: uservalue, store: storevalue };
    if (!cred.user) cred.user = null;
    if (!cred.store) cred.store = null;
    try {
      setLoading(true);
      const res = await axios.post(`/token/generate`, cred);
      toast({
        status: 'success',
        title: 'successfully created token',
      });
      const tokenType = cred.type.split('/')[0];
      const emailTypes = ['user', 'auth', 'store'];
      if (emailTypes.includes(tokenType)) {
        if (res.data.mailStatus.status) {
          toast({
            status: 'success',
            title: 'mail successfully sent',
          });
        } else {
          toast({
            status: 'warning',
            title: 'mail failed to send',
          });
        }
      }
      navigate('/tokens');
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem generating token',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/tokens">
            Tokens
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Generate
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-end"
            spacing="5"
          >
            <FormControl isRequired>
              <FormLabel>Expires</FormLabel>
              <Input
                {...register('expires')}
                variant="filled"
                borderRadius="lg"
                type="datetime-local"
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Type</FormLabel>
              <Select
                {...register('type')}
                variant="filled"
                borderRadius="lg"
                textTransform="lowercase"
              >
                {TOKEN_TYPES.map((el) => (
                  <option key={el}>{el}</option>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
            spacing="5"
          >
            {UserSearch}
            {StoreSearch}
          </Stack>

          <Button
            size="sm"
            alignSelf="flex-end"
            variant="gradient"
            type="submit"
            isLoading={loading}
          >
            Generate
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default GenerateToken;
