import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Divider,
  Flex,
  Text,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import axios from '../config/axios';
import EditTicket from '../pages/events/tickets/id';

export const Ticket = ({
  ticket,
  removeTicket,
  editTicket,
}: {
  ticket: Ticket;
  removeTicket: (id: string) => void;
  editTicket: (ticket: Ticket) => void;
}) => {
  const toast = useToast();
  const [deleteTicketLoading, setDeleteTicketLoading] = useState(false);
  const {
    isOpen: dialogIsOpen,
    onOpen: dialogOpen,
    onClose: dialogOnClose,
  } = useDisclosure();
  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose,
  } = useDisclosure();

  const cancelRef = useRef(null);
  const editInitialRef = useRef(null);
  const editFinalRef = useRef(null);
  const deleteTicket = async () => {
    try {
      setDeleteTicketLoading(true);
      await axios.delete(`/event/ticket/delete/${ticket.id}`);
      toast({
        status: 'success',
        title: 'ticket successfully deleted',
      });
      removeTicket(ticket.id);
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem deleting ticket',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setDeleteTicketLoading(false);
    }
  };

  return (
    <>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={dialogIsOpen}
        onClose={dialogOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bgColor="background.100">
            <AlertDialogHeader>Delete Ticket</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete {ticket.name} ticket?
            </AlertDialogBody>
            <AlertDialogFooter gap="3">
              <Button ref={cancelRef} onClick={dialogOnClose}>
                Cancel
              </Button>
              <Button
                isLoading={deleteTicketLoading}
                colorScheme="red"
                onClick={deleteTicket}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        initialFocusRef={editInitialRef}
        finalFocusRef={editFinalRef}
        isOpen={editIsOpen}
        onClose={editOnClose}
      >
        <ModalOverlay />
        <ModalContent bgColor="background.100" maxW="50vw">
          <ModalHeader>Edit {ticket.name} ticket</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <EditTicket
              ticket={ticket}
              onClose={editOnClose}
              editTicket={editTicket}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box
        bgColor="background.100"
        borderWidth="1px"
        borderColor={ticket.isDefault ? 'brand.100' : 'transparent'}
        p="3"
        borderRadius="lg"
        shadow="dark-lg"
      >
        <Text textTransform="capitalize" isTruncated>
          {ticket.name}
        </Text>
        <Divider borderColor="initial" variant="dashed" my="3" />
        <Text>
          Price: <b>₦{ticket.price.toLocaleString()}</b>
        </Text>
        <Text>
          Quantity: <b>{ticket.count.toLocaleString()}</b>
        </Text>
        <Flex mt="3" alignItems="center" gap="4">
          <Button w="100%" size="sm" onClick={editOnOpen}>
            Edit
          </Button>
          <Button w="100%" size="sm" colorScheme="red" onClick={dialogOpen}>
            Delete
          </Button>
        </Flex>
      </Box>
    </>
  );
};
