import {
  Box,
  HStack,
  Input,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Text,
  IconButton,
  Icon,
  Button,
  useToast,
  useClipboard,
} from '@chakra-ui/react';
import { useGlobalStoreContext } from '../../context';
import { graphqlFetcher } from '../../config/axios';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { usePagination } from '../../hooks';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { ImCross } from 'react-icons/im';
import Loader from '../../components/loader';
import Error from '../../components/error';
import { truncator } from '../../utils';
import { FaCopy } from 'react-icons/fa';

const TokenRow = ({ token, index }: { token: Token; index: number }) => {
  const navigate = useNavigate();
  const { onCopy } = useClipboard(token.token);
  const toast = useToast();
  const copyToken = (text: string) => {
    onCopy();
    toast({
      status: 'success',
      title: 'token copied',
    });
  };
  return (
    <Tr
      key={token.id}
      cursor="pointer"
      _hover={{ backgroundColor: 'background.100' }}
    >
      <Td textTransform="capitalize" onClick={() => navigate(token.id)}>
        {index}
      </Td>
      <Td
        textTransform="capitalize"
        title={token.token}
        onClick={() => navigate(token.id)}
      >
        {truncator(token.token, 16)}
      </Td>
      <Td textTransform="lowercase" onClick={() => navigate(token.id)}>
        {token.type}
      </Td>
      <Td onClick={() => navigate(token.id)}>
        {dayjs(token.expires).format('D/M/YY h:mm A')}
      </Td>
      <Td onClick={() => navigate(token.id)}>
        {dayjs(token.expires) < dayjs() ? (
          <Icon color="green.500" as={BsFillCheckCircleFill} />
        ) : (
          <Icon color="red.500" as={ImCross} />
        )}
      </Td>
      <Td onClick={() => navigate(token.id)}>
        {dayjs(token.createdAt).format('D/M/YY h:mm A')}
      </Td>
      <Td>
        {
          <IconButton
            onClick={() => copyToken('')}
            aria-label="copy token"
            icon={<FaCopy />}
          />
        }
      </Td>
    </Tr>
  );
};

const query = (skip: number, limit: number, keyword: string) => `{
  tokens(skip: ${skip}, limit: ${limit}, keyword: "${keyword}") {
    count
    data {
     id
     token
     type
     expires
     createdAt
    }
  }
}`;

const Tokens = () => {
  const { dispatch } = useGlobalStoreContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [tokens, setTokens] = useState<Token[]>([]);
  const [tokensCount, setTokensCount] = useState(1);
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    setPage,
  } = usePagination({
    count: tokensCount === 0 ? 1 : tokensCount,
    contentPerPage: 20,
  });
  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);
      const users = await graphqlFetcher(
        query(firstContentIndex, lastContentIndex, keyword)
      );
      setTokensCount(users.data.data.tokens.count);
      setTokens(users.data.data.tokens.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [firstContentIndex, lastContentIndex, keyword]);

  useEffect(() => {
    (async () => {
      await fetchUsers();
    })();
  }, [fetchUsers]);

  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'tokens',
        description: 'list of all tokens',
      },
    });
  }, [dispatch]);

  return (
    <Box>
      <HStack justifyContent="center">
        <Input
          w="lg"
          variant="filled"
          placeholder="Search for tokens"
          onChange={(e) => {
            setPage(1);
            setKeyword(e.target.value);
          }}
          value={keyword}
        />
      </HStack>
      <HStack mt="5" justifyContent="space-between">
        <HStack gap="3">
          <Text color="GrayText">
            <b>
              {firstContentIndex + 1} -{' '}
              {lastContentIndex > tokensCount ? tokensCount : lastContentIndex}
            </b>{' '}
            of {tokensCount}
          </Text>
          <HStack>
            <IconButton
              aria-label="prev page"
              onClick={prevPage}
              icon={<span>{'<'}</span>}
              size="sm"
              fontWeight="bold"
              disabled={page === 1}
            />
            <IconButton
              aria-label="next page"
              onClick={nextPage}
              icon={<span>{'>'}</span>}
              size="sm"
              fontWeight="bold"
              disabled={lastContentIndex >= tokensCount}
            />
          </HStack>
        </HStack>
        <Button size="sm" variant="gradient" as={Link} to="generate">
          generate token
        </Button>
      </HStack>
      {loading ? (
        <Loader />
      ) : error ? (
        <Error />
      ) : (
        <Box w="100%" overflow="scroll">
          <Table
            overflowX="auto"
            whiteSpace="nowrap"
            mt="6"
            variant="simple"
            colorScheme="gray"
          >
            <Thead>
              <Tr>
                <Th></Th>
                <Th>token</Th>
                <Th>type</Th>
                <Th>expires</Th>
                <Th>expired</Th>
                <Th>created at</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody fontSize="15px">
              {tokens.map((el, index) => (
                <TokenRow
                  key={el.id}
                  token={el}
                  index={index + firstContentIndex + 1}
                />
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th></Th>
                <Th>first name</Th>
                <Th>last name</Th>
                <Th>email</Th>
                <Th>confirmed</Th>
                <Th>created at</Th>
                <Th></Th>
              </Tr>
            </Tfoot>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default Tokens;
