import { ChakraTheme } from "@chakra-ui/react";

//@ts-ignore
const theme: ChakraTheme = {
    config: {
        initialColorMode: "dark",
        useSystemColorMode: false,
    },
    styles: {
        global: (_) => ({
            body: {
                bg: "#151515",
            },
        }),
    },
    fonts: {
        heading:
            "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
        body:
            "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    },
    colors: {
        brand: {
            100: "#fb7200",
            200: "#feac00",
        },
        strict: {
            100: '#fb7200',
            200: '#fb7200',
            300: '#fb7200',
            400: '#fb7200',
            500: '#fb7200',
            600: '#fb7200',
            700: '#fb7200',
            800: '#fb7200',
            900: '#fb7200',
        },
        background: {
            100: '#202020',
            200: '#151515',
            300: '#121212',
            400: '#111',
        }
    },
    components: {
        Button: {
            baseStyle: {
                shadow: "md",
            },
            variants: {
                gradient: {
                    color: "white",
                    bgGradient: "linear(to-r, brand.100, brand.200)",
                    "&:hover[disabled]": {
                        bgGradient: "linear(to-r, brand.100, brand.200)",
                    },
                    _focus: {
                        bgGradient: "linear(to-r, brand.100, brand.200)",
                        background:
                            "linear-gradient(to right, var(--chakra-colors-brand-100), var(--chakra-colors-brand-200)) !important",
                    },
                    _active: {
                        bgGradient: "linear(to-r, brand.100, brand.200)",
                    },
                },
            },
        },
    }
};

export default theme;