import Axios from 'axios';
import { API_URL } from '.';


const instance = Axios.create({
    baseURL: `${API_URL}/api/v1/`,
});
instance.defaults.withCredentials = true;

export const graphqlFetcher = (query: string) => instance.post(`${API_URL}/graphql`, {
    query,
})

export default instance
