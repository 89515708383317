import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  useToast,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { ReactEventHandler, useMemo, useState } from 'react';
import axios from '../../../config/axios';
import { useForm } from 'react-hook-form';

const CreateMedia = ({
  event,
  createMedia,
}: {
  event: string;
  createMedia: (data: Media) => void;
}) => {
  const {
    handleSubmit,
    register,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [aspectRatio, setAspectRatio] = useState(0);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const fileType = watch('fileType', 'IMG');
  const imageSrc = watch('image', []);

  const isVideo = useMemo(() => fileType === 'VID', [fileType]);

  const imageURL = useMemo(() => {
    if (imageSrc?.length > 0) {
      return URL.createObjectURL(imageSrc[0]);
    }
    return '';
  }, [imageSrc]);

  const onImageLoad: ReactEventHandler<HTMLImageElement> = (img) => {
    // @ts-ignore
    const height = img.target.offsetHeight;
    // @ts-ignore
    const width = img.target.offsetWidth;
    // @ts-ignore
    setAspectRatio(parseFloat(width / height).toFixed(4));
    // free memory
    URL.revokeObjectURL(imageURL);
  };

  const onSubmit = async (data: any) => {
    let cred = {
      ...data,
      event,
      aspectRatio: Number(aspectRatio),
    };
    delete cred.fileType;
    if (cred.mediaType === 'BASIC') delete cred.mediaType;
    let formData = new FormData();
    for (const [key, value] of Object.entries<any>(cred)) {
      if (typeof value === 'object') {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    }
    try {
      setLoading(true);
      const mediaRes = await axios.post('/event/media/create', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      createMedia({
        id: mediaRes.data.data.media.id,
        ...mediaRes.data.data.media,
      });
      toast({
        status: 'success',
        title: 'media created',
      });
      reset();
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'failed to create media',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack w="100%" alignItems="stretch" spacing="10">
        {imageURL && (
          <img
            src={imageURL}
            alt=""
            onLoad={onImageLoad}
            style={{
              height: '100px',
              alignSelf: 'center',
              borderRadius: '10px',
            }}
          />
        )}
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing="5"
          alignItems="flex-start"
        >
          <FormControl id="fileType" isRequired>
            <FormLabel>File Type</FormLabel>
            <Select
              {...register('fileType')}
              variant="filled"
              borderRadius="lg"
              required
            >
              <option value="IMG">Image</option>
              <option value="VID">Video</option>
            </Select>
          </FormControl>
          <FormControl id="fileType">
            <FormLabel>Media Type</FormLabel>
            <Select
              {...register('mediaType')}
              variant="filled"
              borderRadius="lg"
              disabled={isVideo}
            >
              <option value="BASIC">Basic</option>
              <option value="POSTER">Poster</option>
              <option value="BANNER">Banner</option>
            </Select>
          </FormControl>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing="5"
          alignItems="flex-start"
        >
          {isVideo && (
            <FormControl isRequired={isVideo} isInvalid={errors.video}>
              <FormLabel>Video</FormLabel>
              <FormErrorMessage>
                Video should be a maximum of 5MB
              </FormErrorMessage>
              <Input
                {...register('video', {
                  validate: (value) => value && value[0].size < 5100000,
                })}
                variant="filled"
                borderRadius="lg"
                isRequired={isVideo}
                type="file"
                accept="video/*"
              />
            </FormControl>
          )}
          <FormControl isRequired isInvalid={errors.image}>
            <FormLabel>{isVideo ? 'Video Thumbnail' : 'Image'}</FormLabel>
            <FormErrorMessage>
              Image should be a maximum of 2MB
            </FormErrorMessage>
            <Input
              {...register('image', {
                validate: (value) => value && value[0].size < 2200000,
              })}
              variant="filled"
              borderRadius="lg"
              isRequired
              type="file"
              accept="image/*"
            />
          </FormControl>
          <FormControl id="aspectRatio" isReadOnly>
            <FormLabel>Aspect ratio</FormLabel>
            <Input
              {...register('aspectRatio')}
              variant="filled"
              borderRadius="lg"
              type="number"
              value={aspectRatio}
              disabled
            />
          </FormControl>
        </Stack>
        <FormControl id="alt" isRequired>
          <FormLabel>Media Description (alt)</FormLabel>
          <Input
            {...register('alt')}
            variant="filled"
            borderRadius="lg"
            required
          />
        </FormControl>
        <Button
          isLoading={loading}
          type="submit"
          variant="gradient"
          alignSelf="flex-end"
        >
          Create Media
        </Button>
      </VStack>
    </form>
  );
};

export default CreateMedia;
