import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  HStack,
  Input,
  Switch,
  useToast,
  VStack,
  InputGroup,
  InputLeftAddon,
  Stack,
  FormErrorMessage,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import axios from '../../config/axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { useGlobalStoreContext } from '../../context';
import { APP_DATA } from '../../config';

const CreatePsudoUser = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useGlobalStoreContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const eventState = watch('state', 'Lagos');

  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'create psudo user',
        description: 'create psudo user',
      },
    });
  }, [dispatch]);

  const onSubmit = async (cred: any) => {
    let data = { ...cred };
    if (cred.phone.length === 10) {
      data.phone = '0' + cred.phone;
    }
    try {
      setLoading(true);
      await axios.post(`/psudo-user/create`, data);
      toast({
        status: 'success',
        title: 'successfully created user',
      });
      navigate('/psudo-users');
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem creating user',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/psudo-users">
            Psudo Users
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Create
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack direction={{ base: 'column', md: 'row' }}>
            <FormControl id="firstName" isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                {...register('firstName')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
            <FormControl id="lastName" isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                {...register('lastName')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }}>
            <FormControl
              isInvalid={Object.keys(errors).length > 0}
              id="phone"
              isRequired
            >
              <FormLabel>Phone</FormLabel>
              <InputGroup>
                <InputLeftAddon>+234</InputLeftAddon>
                <Input
                  {...register('phone', {
                    pattern: /^[0-9]*$/,
                    minLength: 10,
                    maxLength: 11,
                  })}
                  variant="filled"
                  borderRadius="lg"
                  required
                />
              </InputGroup>
              <FormErrorMessage>Invalid phone number</FormErrorMessage>
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                {...register('email')}
                variant="filled"
                borderRadius="lg"
                type="email"
                required
              />
            </FormControl>
          </Stack>
          <HStack alignItems="flex-end">
            <FormControl id="isConfirmed">
              <FormLabel>used Mall</FormLabel>
              <Switch {...register('usedMall')} colorScheme="strict" />
            </FormControl>
            <FormControl id="isConfirmed">
              <FormLabel>used Ticket</FormLabel>
              <Switch {...register('usedTicket')} colorScheme="strict" />
            </FormControl>
          </HStack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="state">
              <FormLabel>State</FormLabel>
              <Select variant="filled" {...register('state')}>
                <option value="">Select State</option>
                {APP_DATA.states
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((el) => (
                    <option value={el.name} key={el.name}>
                      {el.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl id="lga">
              <FormLabel>Local Government Area</FormLabel>
              <Select variant="filled" {...register('lga')}>
                <option value="">Select Lga</option>
                {APP_DATA.states
                  .find((el) => el.name === eventState)
                  ?.lgas.map((lga) => (
                    <option value={lga} key={lga}>
                      {lga}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <FormControl id="street">
            <FormLabel>Street</FormLabel>
            <Textarea variant="filled" {...register('street')} />
          </FormControl>
          <FormControl id="street">
            <FormLabel>Directions</FormLabel>
            <Textarea variant="filled" {...register('directions')} />
          </FormControl>
          <Button
            alignSelf="flex-end"
            isLoading={loading}
            type="submit"
            variant="gradient"
            size="sm"
          >
            Create User
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default CreatePsudoUser;
