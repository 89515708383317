import { HStack, Text } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

const date = new Date();

const Footer = () => {
  const location = useLocation();
  return (
    <HStack
      h={8}
      w="100%"
      bgColor="background.300"
      justify="center"
      display={location.pathname === '/login' ? 'none' : 'flex'}
    >
      <Text fontSize="sm">&copy; JetronMall {date.getFullYear()}</Text>
    </HStack>
  );
};

export default Footer;
