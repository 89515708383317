import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  HStack,
  Input,
  Switch,
  useToast,
  VStack,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  Stack,
  AlertDialogFooter,
  AlertDialogContent,
  InputGroup,
  InputLeftAddon,
  FormErrorMessage,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import axios from '../../config/axios';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useGlobalStoreContext } from '../../context';
import { useFetch } from '../../hooks';
import Loader from '../../components/loader';
import Error from '../../components/error';
import DangerZone from '../../components/dangerZone';
import { APP_DATA } from '../../config';

const EditPsudoUser = () => {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useGlobalStoreContext();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);
  const cancelRef = useRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [userLoading, userError, userData] = useFetch(`{
    psudoUserById(id: "${id}") {
      firstName
      lastName
      email
      phone
      usedMall
      usedTicket
      state
      lga
      street
      directions
    }
  }`);

  const eventState = watch(
    'state',
    userLoading || userError ? 'Lagos' : userData.psudoUserById.state
  );

  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'edit psudo user',
        description: "edit user's data",
      },
    });
  }, [dispatch]);

  const onUpdateUserSubmit = async (cred: any) => {
    let data = { ...cred };
    if (cred.phone.length === 10) {
      data.phone = '0' + cred.phone;
    }
    try {
      setUpdateUserLoading(true);
      await axios.put(`/psudo-user/update/${id}`, data);
      toast({
        status: 'success',
        title: 'successfully updated user',
      });
      navigate('/psudo-users');
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem updating user',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setUpdateUserLoading(false);
    }
  };

  const deleteUser = async () => {
    try {
      setDeleteUserLoading(true);
      await axios.delete(`/psudo-user/delete/${id}`);
      navigate('/psudo-users');
      toast({
        status: 'success',
        title: 'user successfully deleted',
      });
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem deleting user',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setDeleteUserLoading(false);
    }
  };

  if (userLoading) return <Loader />;
  if (userError) return <Error />;
  return (
    <Box>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/psudo-users">
            Psudo Users
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Edit
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bgColor="background.100">
            <AlertDialogHeader>Delete User</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this user?
            </AlertDialogBody>
            <AlertDialogFooter gap="3">
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isLoading={deleteUserLoading}
                colorScheme="red"
                onClick={deleteUser}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <form onSubmit={handleSubmit(onUpdateUserSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack direction={{ base: 'column', md: 'row' }}>
            <FormControl id="firstName" isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                {...register('firstName')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={userData.psudoUserById.firstName}
              />
            </FormControl>
            <FormControl id="lastName" isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                {...register('lastName')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={userData.psudoUserById.lastName}
              />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }}>
            <FormControl
              isInvalid={Object.keys(errors).length > 0}
              id="phone"
              isRequired
            >
              <FormLabel>Phone</FormLabel>
              <InputGroup>
                <InputLeftAddon>+234</InputLeftAddon>
                <Input
                  {...register('phone', {
                    pattern: /^[0-9]*$/,
                    minLength: 10,
                    maxLength: 11,
                  })}
                  variant="filled"
                  borderRadius="lg"
                  required
                  defaultValue={userData.psudoUserById.phone}
                />
              </InputGroup>
              <FormErrorMessage>Invalid phone number</FormErrorMessage>
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                {...register('email')}
                variant="filled"
                borderRadius="lg"
                type="email"
                required
                defaultValue={userData.psudoUserById.email}
              />
            </FormControl>
          </Stack>
          <HStack alignItems="flex-end">
            <FormControl id="isConfirmed">
              <FormLabel>used Mall</FormLabel>
              <Switch
                {...register('usedMall')}
                defaultChecked={userData.psudoUserById.usedMall}
                colorScheme="strict"
              />
            </FormControl>
            <FormControl id="isConfirmed">
              <FormLabel>used Ticket</FormLabel>
              <Switch
                {...register('usedTicket')}
                defaultChecked={userData.psudoUserById.usedTicket}
                colorScheme="strict"
              />
            </FormControl>
          </HStack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="state">
              <FormLabel>State</FormLabel>
              <Select
                variant="filled"
                {...register('state')}
                defaultValue={userData.psudoUserById.state}
              >
                <option value="">Select State</option>
                {APP_DATA.states
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((el) => (
                    <option value={el.name} key={el.name}>
                      {el.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl id="lga">
              <FormLabel>Local Government Area</FormLabel>
              <Select
                variant="filled"
                {...register('lga')}
                defaultValue={userData.psudoUserById.lga}
              >
                <option value="">Select Lga</option>
                {APP_DATA.states
                  .find((el) => el.name === eventState)
                  ?.lgas.map((lga) => (
                    <option value={lga} key={lga}>
                      {lga}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <FormControl id="street">
            <FormLabel>Street</FormLabel>
            <Textarea
              variant="filled"
              {...register('street')}
              defaultValue={userData.psudoUserById.street}
            />
          </FormControl>
          <FormControl id="street">
            <FormLabel>Directions</FormLabel>
            <Textarea
              variant="filled"
              {...register('directions')}
              defaultValue={userData.psudoUserById.directions}
            />
          </FormControl>
          <Button
            alignSelf="flex-end"
            isLoading={updateUserLoading}
            type="submit"
            variant="gradient"
            size="sm"
          >
            Save changes
          </Button>
        </VStack>
      </form>
      <Divider my="10" />
      <DangerZone action={onOpen} text="Delete User" />
    </Box>
  );
};

export default EditPsudoUser;
