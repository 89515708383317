import { Routes, Route } from 'react-router-dom';
import Login from './pages/login';
import Users from './pages/users';
import PsudoUsers from './pages/psudo-users';
import EditPsudoUser from './pages/psudo-users/id';
import Dashboard from './pages';
import Tokens from './pages/tokens';
import EditUser from './pages/users/id';
import Events from './pages/events';
import CreateUser from './pages/users/create';
import EditToken from './pages/tokens/id';
import GenerateToken from './pages/tokens/generate';
import CreateEvent from './pages/events/create';
import EditEvent from './pages/events/id';
import Sponsors from './pages/events/sponsors';
import CreateSponsor from './pages/events/sponsors/create';
import EditSponsor from './pages/events/sponsors/id';
import Guests from './pages/events/guests';
import CreateGuest from './pages/events/guests/create';
import EditGuest from './pages/events/guests/id';
import TicketOrders from './pages/events/ticket-orders';
import OrderDetails from './pages/events/ticket-orders/id';
import GenerateTicket from './pages/events/ticket-orders/generate';
import TicketScan from './pages/events/ticket-orders/scan';
import DynamicContent from './pages/website-dynamics';
import CreatePsudoUser from './pages/psudo-users/create';

const App = () => {
  return (
    <Routes>
      {/* Auth */}
      <Route path="/login" element={<Login />} />
      {/* Dashboard */}
      <Route path="/" element={<Dashboard />} />
      <Route path="/dashboard" element={<Dashboard />} />
      {/* Users */}
      <Route path="/users" element={<Users />} />
      <Route path="/users/create" element={<CreateUser />} />
      <Route path="/users/:id" element={<EditUser />} />
      {/* PsudoUsers */}
      <Route path="/psudo-users" element={<PsudoUsers />} />
      <Route path="/psudo-users/create" element={<CreatePsudoUser />} />
      <Route path="/psudo-users/:id" element={<EditPsudoUser />} />
      {/* Tokens */}
      <Route path="/tokens" element={<Tokens />} />
      <Route path="/tokens/generate" element={<GenerateToken />} />
      <Route path="/tokens/:id" element={<EditToken />} />
      {/* Events */}
      <Route path="/events" element={<Events />} />
      <Route path="/events/create" element={<CreateEvent />} />
      <Route path="/events/:id" element={<EditEvent />} />
      {/* Sponsors */}
      <Route path="/sponsors" element={<Sponsors />} />
      <Route path="/sponsors/create" element={<CreateSponsor />} />
      <Route path="/sponsors/:id" element={<EditSponsor />} />
      {/* Sponsors */}
      <Route path="/guests" element={<Guests />} />
      <Route path="/guests/create" element={<CreateGuest />} />
      <Route path="/guests/:id" element={<EditGuest />} />
      {/* Ticket orders */}
      <Route path="/ticket-orders" element={<TicketOrders />} />
      <Route path="/ticket-orders/generate" element={<GenerateTicket />} />
      <Route path="/ticket-orders/scan" element={<TicketScan />} />
      <Route path="/ticket-orders/:id" element={<OrderDetails />} />
      {/* Dynamic content */}
      <Route path="/dynamic-content" element={<DynamicContent />} />
    </Routes>
  );
};

// TODO: create auth protected routes
// TODO: create admin protected routes

export default App;
