import { extractPublicId, buildUrl, setConfig } from 'cloudinary-build-url';
import { cloudinaryCloudName } from '../config';

import dayjs from "dayjs";

setConfig({
  cloudName: cloudinaryCloudName,
});

export const truncator = (text: string, num: number) => {
  return text.slice(0, num) + "..."
}

export const randomElementFromArray = (array: any[]) => {
  const index = Math.round(Math.random() * array.length);
  return array[index];
}

export const sanitazeData = (data: any) => {
  let cred = { ...data }
  for (const key in data) {
    if (!cred[key] && cred[key] !== false) {
      delete cred[key];
    }
  }
  return cred;
}

export const formatDefaultDate = (date: string) => {
  return dayjs(date).format(
    'YYYY-MM-DDThh:mm'
  )
}

export const compressImage = (url: string) => {
  if (url && url.includes('res.cloudinary.com')) {
    const publicId = extractPublicId(url);
    return buildUrl(publicId, {
      transformations: {
        resize: {
          height: 100,
        },
        format: "webp",
        quality: 50,
      },
    });
  } else {
    return url;
  }
}