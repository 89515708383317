import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useReducer,
} from 'react';

import Reducer from './reducer';

export const initialState: State = {
  user: {
    id: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    isLoggedIn: false,
    isSocialAdmin: false,
    isAdmin: false,
    isTicketAdmin: false,
    isMallAdmin: false,
    isSuperUser: false,
  },
  sidebarActive: false,
  pageData: {
    title: '',
    description: '',
  },
};

export const globalStoreContext = createContext<{
  state: State;
  dispatch: Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => {},
});

export const GlobalStoreProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <globalStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </globalStoreContext.Provider>
  );
};

export const useGlobalStoreContext: () => {
  state: State;
  dispatch: (action: Action) => void;
} = () => {
  const { state, dispatch } = useContext(globalStoreContext);
  return { state, dispatch };
};
