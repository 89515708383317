import {
  Box,
  Heading,
  HStack,
  Text,
  VStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Icon,
  Grid,
  Image,
  Badge,
  Stack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { ImCross } from 'react-icons/im';
import { Link, useParams } from 'react-router-dom';
import Error from '../../../components/error';
import Loader from '../../../components/loader';
import { useGlobalStoreContext } from '../../../context';
import useFetch from '../../../hooks/useFetch';

const SmallDetail = ({
  title,
  value,
  lowercase,
}: {
  title: string;
  value: string;
  lowercase?: boolean;
}) => {
  return (
    <Box>
      <Text
        fontSize="md"
        mb="3"
        fontWeight="semibold"
        textTransform="capitalize"
        color="brand.100"
      >
        {title}
      </Text>
      <Text
        fontSize="md"
        textTransform={lowercase ? 'lowercase' : 'capitalize'}
      >
        {value}
      </Text>
    </Box>
  );
};

const OrderDetails = () => {
  const { id } = useParams();
  const { dispatch } = useGlobalStoreContext();
  const [ticketTokens, setTicketTokens] = useState<
    { tokenCode: string; tokenUrl: string }[]
  >([]);
  const [orderLoading, orderError, orderData] = useFetch(`{
    ticketOrderById(id: "${id}") {
      ticket {
        name
        event {
          name
        }
      }
      price
      tokens {
        token
      }
      tokenQR
      firstName
      lastName
      email
      payStackRef
      transactionId
      generatedBy
      createdAt
      mailStatus
      timeScanned
    }
  }`);
  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'ticket order details',
        description: 'get full information about ticket purchase',
      },
    });
  }, [dispatch]);
  useEffect(() => {
    if (orderData) {
      setTicketTokens(
        orderData?.ticketOrderById?.tokenQR.map(
          (el: string, index: number) => ({
            tokenUrl: el,
            tokenCode: orderData?.ticketOrderById?.token[index]?.token,
          })
        )
      );
    }
  }, [orderData]);
  console.log(ticketTokens);
  if (orderLoading) return <Loader />;
  if (orderError) return <Error />;
  return (
    <>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/ticket-orders">
            Ticket Orders
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Detail
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <VStack gap="3" alignItems="start">
        <Box>
          <Heading fontSize="lg" color="GrayText" mb="4">
            Event Detail
          </Heading>
          <Stack direction={{ base: 'column', md: 'row' }} gap="8">
            <SmallDetail
              title="name"
              value={orderData?.ticketOrderById?.ticket?.event?.name}
            />
            <SmallDetail
              title="ticket"
              value={orderData?.ticketOrderById?.ticket.name}
            />
            <SmallDetail
              title="price"
              value={`₦${orderData?.ticketOrderById?.price?.toLocaleString()}`}
            />
            <SmallDetail
              title="quantity"
              value={orderData?.ticketOrderById?.tokenQR?.length}
            />
          </Stack>
        </Box>
        <Box>
          <Heading fontSize="lg" color="GrayText" mb="4">
            User Detail
          </Heading>
          <Stack direction={{ base: 'column', md: 'row' }} gap="8">
            <SmallDetail
              title="first name"
              value={orderData?.ticketOrderById?.firstName}
            />
            <SmallDetail
              title="last name"
              value={orderData?.ticketOrderById?.lastName}
            />
            <SmallDetail
              lowercase
              title="email"
              value={orderData?.ticketOrderById?.email}
            />
          </Stack>
        </Box>
        <Box>
          <Heading fontSize="lg" color="GrayText" mb="4">
            Ticket Tokens
          </Heading>
          <Grid
            mb="5"
            gap="4"
            templateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(3, 1fr)',
            }}
            alignItems="center"
          >
            {ticketTokens.map((el) => (
              <VStack>
                <Image height="150px" src={el.tokenUrl} alt={el.tokenCode} />
                <Badge>{el.tokenCode}</Badge>
              </VStack>
            ))}
          </Grid>
        </Box>
        <Box>
          <Heading fontSize="lg" color="GrayText" mb="4">
            Payment
          </Heading>
          <Stack direction={{ base: 'column', md: 'row' }} gap="8">
            <SmallDetail
              title="Ref ID"
              value={
                orderData?.ticketOrderById?.payStackRef
                  ? orderData?.ticketOrderById?.payStackRef
                  : '---'
              }
            />
            <SmallDetail
              title="Trans ID"
              value={
                orderData?.ticketOrderById?.transactionId
                  ? orderData?.ticketOrderById?.transactionId
                  : '---'
              }
            />
            <SmallDetail
              title="generated by"
              lowercase
              value={orderData?.ticketOrderById?.generatedBy}
            />
            <SmallDetail
              title="ordered on"
              value={dayjs(orderData?.ticketOrderById?.createdAt)?.format(
                'MMMM D, YYYY h:mm A'
              )}
            />
          </Stack>
        </Box>
        <Box>
          <Heading fontSize="lg" color="GrayText" mb="4">
            Miscellaneous
          </Heading>
          <HStack gap="8">
            <Box>
              <Text
                fontSize="md"
                mb="3"
                fontWeight="semibold"
                textTransform="capitalize"
                color="brand.100"
              >
                Mail Status
              </Text>
              {orderData?.ticketOrderById?.mailStatus ? (
                <Icon color="green.500" as={BsFillCheckCircleFill} />
              ) : (
                <Icon color="red.500" as={ImCross} />
              )}
            </Box>
            <SmallDetail
              title="last scanned"
              value={
                orderData?.ticketOrderById?.timeScanned
                  ? dayjs(orderData?.ticketOrderById?.timeScanned).format(
                      'MMMM D, YYYY h:mm A'
                    )
                  : '---'
              }
            />
          </HStack>
        </Box>
      </VStack>
    </>
  );
};

export default OrderDetails;
