import {
  Box,
  HStack,
  Input,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Text,
  IconButton,
  Icon,
  Button,
} from '@chakra-ui/react';
import { useGlobalStoreContext } from '../../context';
import { graphqlFetcher } from '../../config/axios';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate, Link } from 'react-router-dom';
import { usePagination } from '../../hooks';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { ImCross } from 'react-icons/im';
import Loader from '../../components/loader';
import Error from '../../components/error';

const query = (skip: number, limit: number, keyword: string) => `{
  psudoUsers(skip: ${skip}, limit: ${limit}, keyword: "${keyword}") {
    count
    data {
      id
      firstName
      lastName
      email
      usedTicket
      usedMall
      createdAt
    }
  }
}`;

const PsudoUsers = () => {
  const { dispatch } = useGlobalStoreContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [users, setUsers] = useState<PsudoUser[]>([]);
  const [usersCount, setUsersCount] = useState(1);
  const navigate = useNavigate();
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    setPage,
  } = usePagination({
    count: usersCount === 0 ? 1 : usersCount,
    contentPerPage: 20,
  });
  const fetchUsers = useCallback(async () => {
    try {
      setLoading(true);
      const users = await graphqlFetcher(
        query(firstContentIndex, lastContentIndex, keyword)
      );
      setUsersCount(users.data.data.psudoUsers.count);
      setUsers(users.data.data.psudoUsers.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [firstContentIndex, lastContentIndex, keyword]);

  useEffect(() => {
    (async () => {
      await fetchUsers();
    })();
  }, [fetchUsers]);

  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'psudo users',
        description: 'list of all psudo users',
      },
    });
  }, [dispatch]);
  return (
    <Box>
      <HStack justifyContent="center">
        <Input
          w="lg"
          variant="filled"
          placeholder="Search for psudo users"
          onChange={(e) => {
            setPage(1);
            setKeyword(e.target.value);
          }}
          value={keyword}
        />
      </HStack>
      <HStack mt="5" justifyContent="space-between">
        <HStack gap="3">
          <Text color="GrayText">
            <b>
              {firstContentIndex + 1} -{' '}
              {lastContentIndex > usersCount ? usersCount : lastContentIndex}
            </b>{' '}
            of {usersCount}
          </Text>
          <HStack>
            <IconButton
              aria-label="prev page"
              onClick={prevPage}
              icon={<span>{'<'}</span>}
              size="sm"
              fontWeight="bold"
              disabled={page === 1}
            />
            <IconButton
              aria-label="next page"
              onClick={nextPage}
              icon={<span>{'>'}</span>}
              size="sm"
              fontWeight="bold"
              disabled={lastContentIndex >= usersCount}
            />
          </HStack>
        </HStack>
        <Button size="sm" variant="gradient" as={Link} to="create">
          create user
        </Button>
      </HStack>
      {loading ? (
        <Loader />
      ) : error ? (
        <Error />
      ) : (
        <Box w="100%" overflow="scroll">
          <Table
            overflowX="auto"
            whiteSpace="nowrap"
            mt="6"
            variant="simple"
            colorScheme="gray"
          >
            <Thead>
              <Tr>
                <Th></Th>
                <Th>first name</Th>
                <Th>last name</Th>
                <Th>email</Th>
                <Th>used mall</Th>
                <Th>used ticket</Th>
                <Th>created at</Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((el, index) => (
                <Tr
                  key={el.id}
                  cursor="pointer"
                  _hover={{ backgroundColor: 'background.100' }}
                  onClick={() => navigate(el.id)}
                >
                  <Td textTransform="capitalize">
                    {index + firstContentIndex + 1}
                  </Td>
                  <Td textTransform="capitalize">{el.firstName}</Td>
                  <Td textTransform="capitalize">{el.lastName}</Td>
                  <Td textTransform="lowercase">{el.email}</Td>
                  <Td>
                    {el.usedMall ? (
                      <Icon color="green.500" as={BsFillCheckCircleFill} />
                    ) : (
                      <Icon color="red.500" as={ImCross} />
                    )}
                  </Td>
                  <Td>
                    {el.usedTicket ? (
                      <Icon color="green.500" as={BsFillCheckCircleFill} />
                    ) : (
                      <Icon color="red.500" as={ImCross} />
                    )}
                  </Td>
                  <Td>{dayjs(el.createdAt).format('D/M/YY h:mm A')}</Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th></Th>
                <Th>first name</Th>
                <Th>last name</Th>
                <Th>email</Th>
                <Th>used mall</Th>
                <Th>used ticket</Th>
                <Th>created at</Th>
              </Tr>
            </Tfoot>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default PsudoUsers;
