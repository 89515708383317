import { Stack, Text } from '@chakra-ui/layout';

const PageLoader = () => {
  return (
    <Stack
      sx={{
        position: 'fixed',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 10000,
      }}
      bgColor="background.300"
      color="white"
    >
      <Text>Loading..</Text>
    </Stack>
  );
};

export default PageLoader;
