import { useEffect, useState } from 'react';
import { useGlobalStoreContext } from '../../../context';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  HStack,
  OrderedList,
  ListItem,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { OnResultFunction, QrReader } from 'react-qr-reader';
import axios from '../../../config/axios';

const TicketScan = () => {
  const { dispatch } = useGlobalStoreContext();
  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'scan ticket',
        description: 'scan tickets',
      },
    });
  }, [dispatch]);
  const [loading, setLoading] = useState(false);
  const [scanned, setScanned] = useState<string[]>([]);
  const toast = useToast();

  const onResult: OnResultFunction = async (result) => {
    if (result) {
      try {
        setLoading(true);
        const resData = await axios.get(
          //@ts-ignore
          `/event/ticket-order/scan/${result.text}`
        );
        toast({
          status: 'success',
          title: 'order successfully generated',
        });
        setScanned((state) => [...state, resData.data.data]);
      } catch (err: any) {
        toast({
          status: 'error',
          title: 'problem scanning ticket',
          description: err.response ? err.response.data.message : '',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/ticket-orders">
            Ticket Orders
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Scan
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <Heading size="md" textAlign="center">
        {loading ? 'Validating QR code' : 'Place QR code infront of camera'}
      </Heading>
      <HStack justifyContent="center">
        <QrReader
          onResult={onResult}
          constraints={{ facingMode: 'user' }}
          scanDelay={1000}
          containerStyle={{
            width: '100%',
            margin: '0',
          }}
        />
      </HStack>
      <Text fontWeight="bold" color="brand.100">
        List of scanned tickets
      </Text>
      <OrderedList>
        {scanned.map((el) => (
          <ListItem key={el}>{el}</ListItem>
        ))}
      </OrderedList>
    </>
  );
};

export default TicketScan;
