import {
  Box,
  IconButton,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  useToast,
  Button,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { compressImage } from '../utils';
import axios from '../config/axios';
import EditMedia from '../pages/events/medias/id';

export const Media = ({
  media,
  remove,
  editMedia,
}: {
  media: Media;
  remove: (id: string) => void;
  editMedia: (media: Media) => void;
}) => {
  const toast = useToast();
  const [deleteMediaLoading, setDeleteMediaLoading] = useState(false);
  const {
    isOpen: dialogIsOpen,
    onOpen: dialogOpen,
    onClose: dialogOnClose,
  } = useDisclosure();
  const {
    isOpen: editIsOpen,
    onOpen: editOnOpen,
    onClose: editOnClose,
  } = useDisclosure();

  const cancelRef = useRef(null);
  const editInitialRef = useRef(null);
  const editFinalRef = useRef(null);

  const deleteMedia = async () => {
    try {
      setDeleteMediaLoading(true);
      await axios.delete(`/event/media/delete/${media.id}`);
      toast({
        status: 'success',
        title: 'media successfully deleted',
      });
      remove(media.id);
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem deleting media',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setDeleteMediaLoading(false);
    }
  };
  console.log(media);
  return (
    <>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={dialogIsOpen}
        onClose={dialogOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bgColor="background.100">
            <AlertDialogHeader>Delete Ticket</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete media?
            </AlertDialogBody>
            <AlertDialogFooter gap="3">
              <Button ref={cancelRef} onClick={dialogOnClose}>
                Cancel
              </Button>
              <Button
                isLoading={deleteMediaLoading}
                colorScheme="red"
                onClick={deleteMedia}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal
        initialFocusRef={editInitialRef}
        finalFocusRef={editFinalRef}
        isOpen={editIsOpen}
        onClose={editOnClose}
      >
        <ModalOverlay />
        <ModalContent bgColor="background.100" maxW="60vw">
          <ModalHeader>Edit media</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <EditMedia
              media={media}
              onClose={editOnClose}
              editMedia={editMedia}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box
        maxWidth="200px"
        position="relative"
        sx={{
          '&:hover': {
            '.close-button': {
              display: 'flex',
            },
          },
        }}
      >
        <IconButton
          className="close-button"
          position="absolute"
          left="-8px"
          top="-8px"
          aria-label="delete media"
          size="xs"
          colorScheme="red"
          icon={<CgClose />}
          display="none"
          onClick={dialogOpen}
        />
        <Image
          key={media.id}
          src={compressImage(media.src)}
          alt={media.alt}
          borderRadius="lg"
          cursor="pointer"
          onClick={editOnOpen}
        />
      </Box>
    </>
  );
};
