import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Stack,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../components/loader';
import Error from '../../components/error';
import { useGlobalStoreContext } from '../../context';
import useFetch from '../../hooks/useFetch';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { TOKEN_TYPES } from '../../config';
import DangerZone from '../../components/dangerZone';
import axios from '../../config/axios';
import useSearchInput from '../../hooks/useSearchInput';
import { formatDefaultDate } from '../../utils';

const EditToken = () => {
  const { id } = useParams();
  const { dispatch } = useGlobalStoreContext();
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();
  const {
    isOpen: dialogIsOpen,
    onOpen: dialogOpen,
    onClose: dialogOnClose,
  } = useDisclosure();
  const { handleSubmit, register } = useForm();
  const cancelRef = useRef(null);
  const [deleteTokenLoading, setDeleteTokenLoading] = useState(false);
  const [updateTokenLoading, setUpdateTokenLoading] = useState(false);
  const [tokenLoading, tokenError, tokenData] = useFetch(`{
    tokenById(id: "${id}") {
      token
      expires
      type
      user {
        id
        firstName
        lastName
      }
    }
  }`);

  const [userValue, setUserValue] = useState('');
  const [userDefaultValue, setUserDefaultValue] = useState({});
  const [usersLoading, usersError, users] = useFetch(`{
    users(keyword: "${userValue}") {
      data {
        id
        firstName
        lastName
      }
    }
  }`);
  const onUserSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const query = e.target.value;
    setUserValue(query);
  };
  const { value: uservalue, component: UserSearch } = useSearchInput({
    label: 'User',
    elements: users ? users.users.data : [],
    error: usersError,
    isRequired: false,
    loading: usersLoading,
    placeholder: 'search for user',
    searchOnChange: onUserSearch,
    searchValue: userValue,
    searchValueKeys: ['firstName', 'lastName'],
    setSearchValue: (text) => setUserValue(text),
    defaultValue: userDefaultValue,
  });
  useEffect(() => {
    setUserDefaultValue(
      tokenData && tokenData.tokenById.user
        ? {
            id: tokenData ? tokenData.tokenById.user.id : '',
            name: tokenData
              ? `${tokenData.tokenById.user.firstName} ${tokenData.tokenById.user.lastName}`
              : '',
          }
        : {
            id: '',
          }
    );
  }, [tokenData]);

  const [storeValue, setStoreValue] = useState('');
  const [storesLoading, storesError, stores] = useFetch(`{
    stores(keyword: "${storeValue}") {
      data {
        id
        firstName
        lastName
      }
    }
  }`);
  const onStoreSearch: ChangeEventHandler<HTMLInputElement> = (e) => {
    const query = e.target.value;
    setStoreValue(query);
  };
  const { value: storevalue, component: StoreSearch } = useSearchInput({
    label: 'Store',
    elements: stores ? stores : [],
    error: storesError,
    isRequired: false,
    loading: storesLoading,
    placeholder: 'search for stores',
    searchOnChange: onStoreSearch,
    searchValue: storeValue,
    searchValueKeys: ['name'],
    setSearchValue: (text) => setStoreValue(text),
    disabled: true,
    defaultValue: { id: '' },
  });
  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'edit token',
        description: 'edit token details e.g expires, user, store etc',
      },
    });
  }, [dispatch]);

  const onSubmit = async (data: any) => {
    let cred = { ...data };
    if (data.token === undefined) {
      cred = {
        ...cred,
        token: tokenData.tokenById.token,
        user: uservalue,
        store: storevalue,
      };
      if (!cred.user) cred.user = null;
      if (!cred.store) cred.store = null;
    }
    try {
      setUpdateTokenLoading(true);
      await axios.put(`/token/update/${id}`, cred);
      toast({
        status: 'success',
        title: 'successfully updated token',
      });
      navigate('/tokens');
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem updating token',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setUpdateTokenLoading(false);
    }
  };

  const deleteToken = async () => {
    try {
      setDeleteTokenLoading(true);
      await axios.delete(`/token/delete/${id}`);
      navigate('/tokens');
      toast({
        status: 'success',
        title: 'token successfully deleted',
      });
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem deleting token',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setDeleteTokenLoading(false);
    }
  };

  if (tokenLoading) return <Loader />;
  if (tokenError) return <Error />;
  return (
    <Box>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/tokens">
            Tokens
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Edit
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={dialogIsOpen}
        onClose={dialogOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bgColor="background.100">
            <AlertDialogHeader>Delete Token</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this token?
            </AlertDialogBody>
            <AlertDialogFooter gap="3">
              <Button ref={cancelRef} onClick={dialogOnClose}>
                Cancel
              </Button>
              <Button
                isLoading={deleteTokenLoading}
                colorScheme="red"
                onClick={deleteToken}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-end"
            spacing="5"
          >
            <FormControl isRequired isDisabled={!isOpen}>
              <FormLabel>Token</FormLabel>
              <InputGroup>
                <Input
                  {...register('token')}
                  variant="filled"
                  borderRadius="lg"
                  defaultValue={tokenData.tokenById.token}
                />
                <InputRightElement width="4.5rem">
                  <IconButton
                    aria-label="update edit token"
                    h="1.75rem"
                    size="sm"
                    onClick={onToggle}
                    icon={isOpen ? <FaLockOpen /> : <FaLock />}
                  ></IconButton>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Expires</FormLabel>
              <Input
                {...register('expires')}
                defaultValue={formatDefaultDate(tokenData.tokenById.expires)}
                variant="filled"
                borderRadius="lg"
                type="datetime-local"
              />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }} spacing="5">
            {UserSearch}
            {StoreSearch}
          </Stack>
          <FormControl isRequired w="49%">
            <FormLabel>Type</FormLabel>
            <Select
              {...register('type')}
              variant="filled"
              borderRadius="lg"
              textTransform="lowercase"
              defaultValue={tokenData.tokenById.type}
            >
              {TOKEN_TYPES.map((el) => (
                <option key={el}>{el}</option>
              ))}
            </Select>
          </FormControl>
          <Button
            size="sm"
            alignSelf="flex-end"
            variant="gradient"
            type="submit"
            isLoading={updateTokenLoading}
          >
            Save changes
          </Button>
        </VStack>
      </form>
      <Divider my="10" />
      <DangerZone action={dialogOpen} text="Delete Token" />
    </Box>
  );
};

export default EditToken;
