import { useState, useEffect } from "react";
import { graphqlFetcher } from "../config/axios";

const useFetch: UseFetch = (query: string) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const fetchedData = await graphqlFetcher(query);
                if (fetchedData.data.errors) {
                    setError(true);
                } else {
                    setData(fetchedData.data.data);
                }
            } catch (err) {
                setError(true);
            } finally {
                setLoading(false);
            }
        })()
    }, [query])
    return [loading, error, data];
}

export default useFetch;