import {
  Box,
  HStack,
  Input,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Text,
  IconButton,
  Button,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { useGlobalStoreContext } from '../../../context';
import { graphqlFetcher } from '../../../config/axios';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { usePagination } from '../../../hooks';
import Loader from '../../../components/loader';
import Error from '../../../components/error';

const query = (skip: number, limit: number, keyword: string) => `{
  specialGuests(skip: ${skip}, limit: ${limit}, keyword: "${keyword}") {
    count
    data {
      id
      stageName
      image
      website
      createdAt
    }
  }
}`;

const Guests = () => {
  const { dispatch } = useGlobalStoreContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [guests, setGuests] = useState<Guest[]>([]);
  const [guestsCount, setGuestsCount] = useState(1);
  const navigate = useNavigate();
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    setPage,
  } = usePagination({
    count: guestsCount === 0 ? 1 : guestsCount,
    contentPerPage: 20,
  });
  const fetchGuests = useCallback(async () => {
    try {
      setLoading(true);
      const gottenGuests = await graphqlFetcher(
        query(firstContentIndex, lastContentIndex, keyword)
      );
      setGuestsCount(gottenGuests.data.data.specialGuests.count);
      setGuests(gottenGuests.data.data.specialGuests.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [firstContentIndex, lastContentIndex, keyword]);

  useEffect(() => {
    (async () => {
      await fetchGuests();
    })();
  }, [fetchGuests]);

  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'guests',
        description: "list of all event's guests",
      },
    });
  }, [dispatch]);
  return (
    <Box>
      <HStack justifyContent="center">
        <Input
          w="lg"
          variant="filled"
          placeholder="Search for guests"
          onChange={(e) => {
            setPage(1);
            setKeyword(e.target.value);
          }}
          value={keyword}
        />
      </HStack>
      <HStack mt="5" justifyContent="space-between">
        <HStack gap="3">
          <Text color="GrayText">
            <b>
              {firstContentIndex + 1} -{' '}
              {lastContentIndex > guestsCount ? guestsCount : lastContentIndex}
            </b>{' '}
            of {guestsCount}
          </Text>
          <HStack>
            <IconButton
              aria-label="prev page"
              onClick={prevPage}
              icon={<span>{'<'}</span>}
              size="sm"
              fontWeight="bold"
              disabled={page === 1}
            />
            <IconButton
              aria-label="next page"
              onClick={nextPage}
              icon={<span>{'>'}</span>}
              size="sm"
              fontWeight="bold"
              disabled={lastContentIndex >= guestsCount}
            />
          </HStack>
        </HStack>
        <Button size="sm" variant="gradient" as={Link} to="create">
          create guest
        </Button>
      </HStack>
      {loading ? (
        <Loader />
      ) : error ? (
        <Error />
      ) : (
        <Box w="100%" overflow="scroll">
          <Table
            overflowX="auto"
            whiteSpace="nowrap"
            mt="6"
            variant="simple"
            colorScheme="gray"
          >
            <Thead>
              <Tr>
                <Th></Th>
                <Th>name</Th>
                <Th>website</Th>
                <Th>created at</Th>
              </Tr>
            </Thead>
            <Tbody>
              {guests.map((el, index) => (
                <Tr
                  key={el.id}
                  cursor="pointer"
                  _hover={{ backgroundColor: 'background.100' }}
                  onClick={() => navigate(el.id)}
                >
                  <Td textTransform="capitalize">
                    {index + firstContentIndex + 1}
                  </Td>
                  <Td textTransform="capitalize">{el.stageName}</Td>
                  <Td textTransform="lowercase">
                    <ChakraLink color="brand.100" href={el.website} isExternal>
                      {el.website}
                    </ChakraLink>
                  </Td>

                  <Td>{dayjs(el.createdAt).format('MMM D, YYYY h:mm A')}</Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th></Th>
                <Th>name</Th>
                <Th>website</Th>
                <Th>created at</Th>
              </Tr>
            </Tfoot>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default Guests;
