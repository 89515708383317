import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  Link as ChakraLink,
  FormLabel,
  Input,
  Stack,
  Text,
  useToast,
  VStack,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import axios from '../../../config/axios';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGlobalStoreContext } from '../../../context';
import useFetch from '../../../hooks/useFetch';
import Loader from '../../../components/loader';
import Error from '../../../components/error';
import { compressImage } from '../../../utils';
import DangerZone from '../../../components/dangerZone';

const EditGuest = () => {
  const { dispatch } = useGlobalStoreContext();
  const { handleSubmit, register } = useForm();
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [guestLoading, guestError, guest] = useFetch(`{
    specialGuestById(id: "${id}") {
      stageName
      firstName
      lastName
      image
      website
    }
  }`);

  const {
    isOpen: dialogIsOpen,
    onOpen: dialogOpen,
    onClose: dialogOnClose,
  } = useDisclosure();

  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'update guest',
        description: 'update event guest',
      },
    });
  }, [dispatch]);

  const cancelRef = useRef(null);

  const onSubmit = async (data: any) => {
    let formData = new FormData();
    for (const [key, value] of Object.entries<any>(data)) {
      if (typeof value === 'object') {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    }
    try {
      setLoading(true);
      setLoading(true);
      await axios.put(`/event/special-guest/update/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      toast({
        status: 'success',
        title: 'guest updated',
      });
      navigate('/guests');
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'failed to update guest',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setLoading(false);
    }
  };

  const [deleteGuestLoading, setDeleteGuestLoading] = useState(false);

  const deleteGuest = async () => {
    try {
      setDeleteGuestLoading(true);
      await axios.delete(`/event/special-guest/delete/${id}`);
      navigate('/guests');
      toast({
        status: 'success',
        title: 'guest successfully deleted',
      });
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem deleting guest',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setDeleteGuestLoading(false);
    }
  };

  if (guestLoading) return <Loader />;

  if (guestError) return <Error />;

  return (
    <Box>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/guests">
            Guest
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Update
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={dialogIsOpen}
        onClose={dialogOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bgColor="background.100">
            <AlertDialogHeader>Delete Guest</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this guest?
            </AlertDialogBody>
            <AlertDialogFooter gap="3">
              <Button ref={cancelRef} onClick={dialogOnClose}>
                Cancel
              </Button>
              <Button
                isLoading={deleteGuestLoading}
                colorScheme="red"
                onClick={deleteGuest}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="stageName" isRequired>
              <FormLabel>Stage Name</FormLabel>
              <Input
                {...register('stageName')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={guest.specialGuestById.stageName}
              />
            </FormControl>
            <FormControl id="website" isRequired>
              <FormLabel>Website</FormLabel>
              <Input
                {...register('website')}
                variant="filled"
                borderRadius="lg"
                type="url"
                required
                defaultValue={guest.specialGuestById.website}
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="firstName" isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                {...register('firstName')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={guest.specialGuestById.firstName}
              />
            </FormControl>
            <FormControl id="lastName" isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                {...register('lastName')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={guest.specialGuestById.lastName}
              />
            </FormControl>
          </Stack>
          <FormControl id="website" isRequired>
            <FormLabel>Logo</FormLabel>
            <Text w="lg" isTruncated mb="2">
              Current
              <ChakraLink
                opacity="0.8"
                color="brand.100"
                fontSize="sm"
                isExternal
                href={compressImage(guest.specialGuestById.image)}
              >
                {compressImage(guest.specialGuestById.image)}
              </ChakraLink>
            </Text>
            <Input
              {...register('image')}
              variant="filled"
              type="file"
              accept="image/*"
              borderRadius="lg"
              required
            />
          </FormControl>
          <Button
            isLoading={loading}
            type="submit"
            variant="gradient"
            alignSelf="flex-end"
          >
            Update Guest
          </Button>
        </VStack>
      </form>
      <Divider my="10" />
      <DangerZone action={dialogOpen} text="Delete Guest" />
    </Box>
  );
};

export default EditGuest;
