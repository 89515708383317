import { useGlobalStoreContext } from '../../../context';
import {
  Box,
  HStack,
  Input,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Text,
  IconButton,
  Button,
  Icon,
  VStack,
} from '@chakra-ui/react';
import { graphqlFetcher } from '../../../config/axios';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { usePagination } from '../../../hooks';
import Loader from '../../../components/loader';
import Error from '../../../components/error';
import dayjs from 'dayjs';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { ImCross } from 'react-icons/im';

const query = (skip: number, limit: number, keyword: string) => `{
  ticketOrders(skip: ${skip}, limit: ${limit}, keyword: "${keyword}") {
    count
    data {
      id
      email
      mailStatus
      createdAt
      ticket {
        name
        event {
          name
        }
      }
    }
  }
}`;

const TicketOrders = () => {
  const { dispatch } = useGlobalStoreContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [orders, setOrders] = useState<TicketOrder[]>([]);
  const [ordersCount, setOrdersCount] = useState(1);
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    setPage,
  } = usePagination({
    count: ordersCount === 0 ? 1 : ordersCount,
    contentPerPage: 20,
  });

  const fetchOrders = useCallback(async () => {
    try {
      setLoading(true);
      const orders = await graphqlFetcher(
        query(firstContentIndex, lastContentIndex, keyword)
      );
      setOrdersCount(orders.data.data.ticketOrders.count);
      setOrders(orders.data.data.ticketOrders.data);
    } catch (err) {
      console.log(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [firstContentIndex, lastContentIndex, keyword]);

  useEffect(() => {
    (async () => {
      await fetchOrders();
    })();
  }, [fetchOrders]);

  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'ticket orders',
        description: 'list of all ticket orders',
      },
    });
  }, [dispatch]);

  return (
    <>
      <Box>
        <HStack justifyContent="center">
          <Input
            w="lg"
            variant="filled"
            placeholder="Search ticket orders"
            onChange={(e) => {
              setPage(1);
              setKeyword(e.target.value);
            }}
            value={keyword}
          />
        </HStack>
        <HStack mt="5" justifyContent="space-between">
          <HStack gap="3">
            <Text color="GrayText">
              <b>
                {firstContentIndex + 1} -{' '}
                {lastContentIndex > ordersCount
                  ? ordersCount
                  : lastContentIndex}
              </b>{' '}
              of {ordersCount}
            </Text>
            <HStack>
              <IconButton
                aria-label="prev page"
                onClick={prevPage}
                icon={<span>{'<'}</span>}
                size="sm"
                fontWeight="bold"
                disabled={page === 1}
              />
              <IconButton
                aria-label="next page"
                onClick={nextPage}
                icon={<span>{'>'}</span>}
                size="sm"
                fontWeight="bold"
                disabled={lastContentIndex >= ordersCount}
              />
            </HStack>
          </HStack>
          <VStack alignItems="stretch">
            <Button size="sm" as={Link} to="generate">
              generate ticket
            </Button>
            <Button size="sm" variant="gradient" as={Link} to="scan">
              scan ticket
            </Button>
          </VStack>
        </HStack>
        {loading ? (
          <Loader />
        ) : error ? (
          <Error />
        ) : (
          <Box w="100%" overflow="scroll">
            <Table
              overflowX="auto"
              whiteSpace="nowrap"
              mt="6"
              variant="simple"
              colorScheme="gray"
            >
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>ticket</Th>
                  <Th>event</Th>
                  <Th>user</Th>
                  <Th>mail status</Th>
                  <Th>created at</Th>
                </Tr>
              </Thead>
              <Tbody>
                {orders.map((el, index) => (
                  <OrderRow index={index + firstContentIndex + 1} order={el} />
                ))}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th></Th>
                  <Th>ticket</Th>
                  <Th>event</Th>
                  <Th>user</Th>
                  <Th>mail status</Th>
                  <Th>created at</Th>
                </Tr>
              </Tfoot>
            </Table>
          </Box>
        )}
      </Box>
    </>
  );
};

export default TicketOrders;

const OrderRow = ({ order, index }: { order: TicketOrder; index: number }) => {
  const navigate = useNavigate();
  return (
    <Tr
      cursor="pointer"
      _hover={{
        backgroundColor: 'background.100',
      }}
      onClick={() => navigate(order.id)}
    >
      <Td textTransform="capitalize">{index}</Td>
      <Td textTransform="capitalize">{order?.ticket?.name}</Td>
      <Td textTransform="capitalize">{order?.ticket?.event?.name}</Td>
      <Td>{order?.email}</Td>
      <Td>
        {order.mailStatus ? (
          <Icon color="green.500" as={BsFillCheckCircleFill} />
        ) : (
          <Icon color="red.500" as={ImCross} />
        )}
      </Td>
      <Td>{dayjs(order.createdAt).format('MMM D, YYYY h:mm A')}</Td>
    </Tr>
  );
};
