import {
  HStack,
  chakra,
  Heading,
  Button,
  IconButton,
  Icon,
  useToast,
  useColorMode,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ImExit } from 'react-icons/im';
import axios from '../../config/axios';
import { useEffect, useState } from 'react';
import { useGlobalStoreContext } from '../../context';
import { GiHamburgerMenu } from 'react-icons/gi';

const Navbar = () => {
  const { dispatch } = useGlobalStoreContext();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { setColorMode } = useColorMode();
  const logout = async () => {
    try {
      setLoading(true);
      navigate('/login');
      await axios.get('/auth/logout');
      toast({
        title: 'Logged out successfully',
        duration: 3000,
        status: 'success',
      });
    } catch {
      toast({
        title: 'Problem logging out',
        duration: 3000,
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    setColorMode('dark');
  }, [setColorMode]);

  const toggleSidebar = () => {
    dispatch({
      type: 'TOGGLE_SIDEBAR',
    });
  };
  return (
    <HStack
      h={16}
      w="100%"
      px={4}
      bgColor="background.300"
      display={location.pathname === '/login' ? 'none' : 'flex'}
      justify="space-between"
    >
      <Heading
        onClick={() => navigate('/')}
        cursor="pointer"
        fontWeight="600"
        fontSize={{ base: 'md', lg: 'lg' }}
      >
        JETRON
        <chakra.span color="brand.100" fontWeight="500">
          MALL
        </chakra.span>{' '}
      </Heading>
      <HStack gap="4">
        <Button
          isLoading={loading}
          onClick={logout}
          colorScheme="red"
          size="sm"
        >
          Logout
          <Icon ml={2} as={ImExit} />
        </Button>
        <IconButton
          display={{
            base: 'flex',
            lg: 'none',
          }}
          size="sm"
          onClick={toggleSidebar}
          aria-label="toggle sidebar"
          icon={<GiHamburgerMenu />}
        />
      </HStack>
    </HStack>
  );
};

export default Navbar;
