import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  useToast,
  Stack,
  VStack,
} from '@chakra-ui/react';
import axios from '../../config/axios';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Error from '../../components/error';
import Loader from '../../components/loader';
import { useGlobalStoreContext } from '../../context';
import { useFetch } from '../../hooks';

const DynamicContent = () => {
  const { dispatch } = useGlobalStoreContext();
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register } = useForm();
  const toast = useToast();
  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'dynamic content',
        description: 'setup website dynamic content',
      },
    });
  }, [dispatch]);

  const [eventsLoading, eventsError, events] = useFetch(`{
    events {
      data {
        id
        name
      }
    }
  }`);

  const [defualtValuesLoading, defaultValuesError, defaultValues] = useFetch(`{
    websiteDynamics {
      sponsoredEvent {
        id
      }
      featuredEvent {
        id
      }
    }
  }`);
  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      await axios.post('/website-dynamics/manage', data);
      toast({
        status: 'success',
        title: 'updated dynamic content',
      });
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem generating order',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setLoading(false);
    }
  };

  if (defualtValuesLoading) return <Loader />;
  if (defaultValuesError) return <Error />;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack w="100%" alignItems="stretch" spacing="10">
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing="5"
          alignItems="flex-end"
        >
          <FormControl id="firstName" isRequired>
            <FormLabel>Sponsored Event</FormLabel>
            {(eventsLoading || eventsError) && (
              <FormHelperText
                mb="3"
                color={eventsError ? 'red.400' : 'initial'}
              >
                {eventsLoading
                  ? 'Fetching events'
                  : eventsError
                  ? 'Error fetching events'
                  : ''}
              </FormHelperText>
            )}
            <Select
              {...register('sponsoredEventId')}
              variant="filled"
              borderRadius="lg"
              required
              placeholder="select event"
              textTransform="capitalize"
              defaultValue={defaultValues.websiteDynamics?.sponsoredEvent?.id}
            >
              {events &&
                events.events.data
                  .sort((a: Event, b: Event) => a.name.localeCompare(b.name))
                  .map((el: Event) => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
            </Select>
          </FormControl>
          <FormControl id="lastName" isRequired>
            <FormLabel>Featured Event</FormLabel>
            {(eventsLoading || eventsError) && (
              <FormHelperText
                mb="3"
                color={eventsError ? 'red.400' : 'initial'}
              >
                {eventsLoading
                  ? 'Fetching events'
                  : eventsError
                  ? 'Error fetching events'
                  : ''}
              </FormHelperText>
            )}
            <Select
              {...register('featuredEventId')}
              variant="filled"
              borderRadius="lg"
              required
              placeholder="select event"
              textTransform="capitalize"
              defaultValue={defaultValues.websiteDynamics?.featuredEvent?.id}
            >
              {events &&
                events.events.data
                  .sort((a: Event, b: Event) => a.name.localeCompare(b.name))
                  .map((el: Event) => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
            </Select>
          </FormControl>
        </Stack>
        <Button
          isLoading={loading}
          type="submit"
          variant="gradient"
          alignSelf="flex-end"
        >
          Update
        </Button>
      </VStack>
    </form>
  );
};

export default DynamicContent;
