import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Stack,
  Icon,
  Input,
  Switch,
  Tag,
  Text,
  useToast,
  VStack,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  InputGroup,
  InputLeftAddon,
  FormErrorMessage,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/react';
import axios from '../../config/axios';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useGlobalStoreContext } from '../../context';
import { useFetch } from '../../hooks';
import { ImCross } from 'react-icons/im';
import Loader from '../../components/loader';
import Error from '../../components/error';
import DangerZone from '../../components/dangerZone';
import { colorThemeArray } from '../../config';

const EditUser = () => {
  const { id } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const { dispatch } = useGlobalStoreContext();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);
  const cancelRef = useRef(null);
  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useForm();
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [updateRoleLoading, setUpdateRoleLoading] = useState(false);
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);
  const [rolesKeyword, setRolesKeyword] = useState('');
  const [rolesSearch, setRolesSearch] = useState<Role[]>([]);
  const [userLoading, userError, userData] = useFetch(`{
    userById(id: "${id}") {
      firstName
      lastName
      email
      phone
      isConfirmed
      roles {
        id
        name
      }
    }
  }`);
  const [rolesLoading, rolesError, rolesData] = useFetch(`{
    roles {
      id
      name
    }
  }`);

  const [roles, setRoles] = useState<Role[]>([]);
  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'edit user',
        description: "edit user's data and roles management",
      },
    });
  }, [dispatch]);

  useEffect(() => {
    setRoles(userData ? userData.userById.roles : []);
  }, [userData, userLoading, userError]);

  const onUpdateUserSubmit = async (cred: any) => {
    let data = { ...cred };
    if (cred.phone.length === 10) {
      data.phone = '0' + cred.phone;
    }
    try {
      setUpdateUserLoading(true);
      const res = await axios.put(`/user/admin-update/${id}`, data);
      if (
        res.data.data.mailStatus.status &&
        userData.userById.email !== cred.email
      ) {
        toast({
          status: 'success',
          title: 'successfully sent mail',
        });
      } else {
        if (userData.userById.email !== cred.email) {
          toast({
            status: 'warning',
            title: 'failed to send mail',
          });
        }
      }
      toast({
        status: 'success',
        title: 'successfully updated user',
      });
      navigate('/users');
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem updating user',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setUpdateUserLoading(false);
    }
  };

  const onUpdatePasswordSubmit = async (cred: any) => {
    try {
      setUpdatePasswordLoading(true);
      await axios.patch(`/user/set-password/${id}`, cred);
      toast({
        status: 'success',
        title: 'successfully set password',
      });
      resetField('password');
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem setting password',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setUpdatePasswordLoading(false);
    }
  };

  const removeRole = async (roleId: string) => {
    try {
      setUpdateRoleLoading(true);
      await axios.delete(`/user/remove-role/${id}/${roleId}`);
      setRoles((state) => state.filter((el: any) => el.id !== roleId));
      toast({
        status: 'success',
        title: 'successfully removed role',
      });
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem removing role',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setUpdateRoleLoading(false);
    }
  };

  const addRole = async (role: Role) => {
    try {
      setUpdateRoleLoading(true);
      await axios.get(`/user/add-role/${id}/${role.id}`);
      setRoles((state) => [...state, role]);
      toast({
        status: 'success',
        title: 'successfully added role',
      });
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem adding role',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setRolesKeyword('');
      setRolesSearch([]);
      setUpdateRoleLoading(false);
    }
  };

  const handleSearchRole = async (e: ChangeEvent<HTMLInputElement>) => {
    setRolesKeyword(e.target.value);
    if (e.target.value.length > 0) {
      setRolesSearch(
        rolesData
          ? rolesData.roles.filter((role: any) =>
              role.name.toLowerCase().includes(e.target.value.toLowerCase())
            )
          : []
      );
    } else {
      setRolesSearch([]);
    }
  };

  const deleteUser = async () => {
    try {
      setDeleteUserLoading(true);
      await axios.delete(`/user/delete/${id}`);
      navigate('/users');
      toast({
        status: 'success',
        title: 'user successfully deleted',
      });
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem deleting user',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setDeleteUserLoading(false);
    }
  };

  if (userLoading) return <Loader />;
  if (userError) return <Error />;
  return (
    <Box>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/users">
            Users
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Edit
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bgColor="background.100">
            <AlertDialogHeader>Delete User</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this user?
            </AlertDialogBody>
            <AlertDialogFooter gap="3">
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button
                isLoading={deleteUserLoading}
                colorScheme="red"
                onClick={deleteUser}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <form onSubmit={handleSubmit(onUpdateUserSubmit)}>
        <Heading fontSize="lg" mb="5" color="GrayText">
          Edit Details
        </Heading>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack direction={{ base: 'column', md: 'row' }}>
            <FormControl id="firstName" isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                {...register('firstName')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={userData.userById.firstName}
              />
            </FormControl>
            <FormControl id="lastName" isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                {...register('lastName')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={userData.userById.lastName}
              />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }}>
            <FormControl
              isInvalid={Object.keys(errors).length > 0}
              id="phone"
              isRequired
            >
              <FormLabel>Phone</FormLabel>
              <InputGroup>
                <InputLeftAddon>+234</InputLeftAddon>
                <Input
                  {...register('phone', {
                    pattern: /^[0-9]*$/,
                    minLength: 10,
                    maxLength: 11,
                  })}
                  variant="filled"
                  borderRadius="lg"
                  required
                  defaultValue={userData.userById.phone}
                />
              </InputGroup>
              <FormErrorMessage>Invalid phone number</FormErrorMessage>
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                {...register('email')}
                variant="filled"
                borderRadius="lg"
                type="email"
                required
                defaultValue={userData.userById.email}
              />
            </FormControl>
          </Stack>
          <HStack alignItems="flex-end">
            <FormControl id="isConfirmed">
              <FormLabel>is Confirmed</FormLabel>
              <Switch
                {...register('isConfirmed')}
                defaultChecked={userData.userById.isConfirmed}
                colorScheme="strict"
              />
            </FormControl>
            <Button
              flexShrink="0"
              isLoading={updateUserLoading}
              type="submit"
              variant="gradient"
              size="sm"
            >
              Save changes
            </Button>
          </HStack>
        </VStack>
      </form>
      <Divider my="10" />
      <form onSubmit={handleSubmit(onUpdatePasswordSubmit)}>
        <Heading fontSize="lg" mb="5" color="GrayText">
          Change Password
        </Heading>
        <FormControl id="password" isRequired>
          <FormLabel>Password</FormLabel>
          <Input
            {...register('password')}
            variant="filled"
            borderRadius="lg"
            required
          />
        </FormControl>
        <HStack justifyContent="flex-end">
          <Button
            type="submit"
            isLoading={updatePasswordLoading}
            variant="gradient"
            mt="10"
            size="sm"
          >
            Change
          </Button>
        </HStack>
      </form>
      <Divider my="10" />
      <Heading fontSize="lg" mb="5" color="GrayText">
        Changes Roles
      </Heading>
      <Text>Roles</Text>
      <HStack
        px="2"
        borderRadius="lg"
        mt="3"
        bgColor="whiteAlpha.50"
        h="10"
        w="100%"
      >
        {updateRoleLoading ? (
          <Text color="GrayText">Updating changes...</Text>
        ) : (
          roles.map((el: any, index) => (
            <Tag
              key={el.id}
              minW="36"
              colorScheme={colorThemeArray[index % colorThemeArray.length]}
            >
              <Icon
                cursor="pointer"
                boxSize="3"
                as={ImCross}
                mr="3"
                onClick={() => removeRole(el.id)}
              />{' '}
              {el.name.replace('_', ' ')}
            </Tag>
          ))
        )}
        <Box w="40" position="relative">
          <Input
            variant="unstyled"
            value={rolesKeyword}
            onChange={handleSearchRole}
          />
          <Box
            position="absolute"
            maxW="60"
            minW="40"
            bottom="10"
            bgColor="background.300"
            shadow="dark-lg"
            borderRadius="lg"
            overflow="hidden"
          >
            {rolesLoading ? (
              <Text
                px="4"
                py="2"
                backgroundColor="background.100"
                _hover={{
                  bgColor: 'background.200',
                }}
                isTruncated
              >
                loading...
              </Text>
            ) : rolesError ? (
              <Text
                px="4"
                py="2"
                backgroundColor="background.100"
                _hover={{
                  bgColor: 'background.200',
                }}
                isTruncated
                color="red.400"
              >
                error
              </Text>
            ) : (
              rolesSearch
                .filter((el) => !roles.some((role) => role.id === el.id))
                .map((el) => (
                  <Text
                    px="4"
                    py="2"
                    backgroundColor="background.100"
                    _hover={{
                      bgColor: 'background.200',
                    }}
                    cursor="pointer"
                    isTruncated
                    fontSize="14px"
                    key={el.id}
                    onClick={() => addRole(el)}
                  >
                    {el.name.replaceAll('_', ' ')}
                  </Text>
                ))
            )}
          </Box>
        </Box>
      </HStack>
      <Divider my="10" />
      <DangerZone action={onOpen} text="Delete User" />
    </Box>
  );
};

export default EditUser;
