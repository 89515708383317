import {
  Box,
  HStack,
  Input,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  Text,
  IconButton,
  Button,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { useGlobalStoreContext } from '../../../context';
import { graphqlFetcher } from '../../../config/axios';
import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { Link, useNavigate } from 'react-router-dom';
import { usePagination } from '../../../hooks';
import Loader from '../../../components/loader';
import Error from '../../../components/error';

const query = (skip: number, limit: number, keyword: string) => `{
  sponsors(skip: ${skip}, limit: ${limit}, keyword: "${keyword}") {
    count
    data {
      id
      name
      image
      website
      createdAt
    }
  }
}`;

const Sponsors = () => {
  const { dispatch } = useGlobalStoreContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [sponsors, setSponsors] = useState<Sponsor[]>([]);
  const [sponsorsCount, setSponsorsCount] = useState(1);
  const navigate = useNavigate();
  const {
    firstContentIndex,
    lastContentIndex,
    nextPage,
    prevPage,
    page,
    setPage,
  } = usePagination({
    count: sponsorsCount === 0 ? 1 : sponsorsCount,
    contentPerPage: 20,
  });
  const fetchSponsors = useCallback(async () => {
    try {
      setLoading(true);
      const gottenSponsors = await graphqlFetcher(
        query(firstContentIndex, lastContentIndex, keyword)
      );
      setSponsorsCount(gottenSponsors.data.data.sponsors.count);
      setSponsors(gottenSponsors.data.data.sponsors.data);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [firstContentIndex, lastContentIndex, keyword]);

  useEffect(() => {
    (async () => {
      await fetchSponsors();
    })();
  }, [fetchSponsors]);

  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'sponsors',
        description: "list of all event's sponsors",
      },
    });
  }, [dispatch]);
  return (
    <Box>
      <HStack justifyContent="center">
        <Input
          w="lg"
          variant="filled"
          placeholder="Search for sponsors"
          onChange={(e) => {
            setPage(1);
            setKeyword(e.target.value);
          }}
          value={keyword}
        />
      </HStack>
      <HStack mt="5" justifyContent="space-between">
        <HStack gap="3">
          <Text color="GrayText">
            <b>
              {firstContentIndex + 1} -{' '}
              {lastContentIndex > sponsorsCount
                ? sponsorsCount
                : lastContentIndex}
            </b>{' '}
            of {sponsorsCount}
          </Text>
          <HStack>
            <IconButton
              aria-label="prev page"
              onClick={prevPage}
              icon={<span>{'<'}</span>}
              size="sm"
              fontWeight="bold"
              disabled={page === 1}
            />
            <IconButton
              aria-label="next page"
              onClick={nextPage}
              icon={<span>{'>'}</span>}
              size="sm"
              fontWeight="bold"
              disabled={lastContentIndex >= sponsorsCount}
            />
          </HStack>
        </HStack>
        <Button size="sm" variant="gradient" as={Link} to="create">
          create sponsor
        </Button>
      </HStack>
      {loading ? (
        <Loader />
      ) : error ? (
        <Error />
      ) : (
        <Box w="100%" overflow="scroll">
          <Table
            overflowX="auto"
            whiteSpace="nowrap"
            mt="6"
            variant="simple"
            colorScheme="gray"
          >
            <Thead>
              <Tr>
                <Th></Th>
                <Th>name</Th>
                <Th>website</Th>
                <Th>created at</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sponsors.map((el, index) => (
                <Tr
                  key={el.id}
                  cursor="pointer"
                  _hover={{ backgroundColor: 'background.100' }}
                  onClick={() => navigate(el.id)}
                >
                  <Td textTransform="capitalize">
                    {index + firstContentIndex + 1}
                  </Td>
                  <Td textTransform="capitalize">{el.name}</Td>
                  <Td textTransform="lowercase">
                    <ChakraLink color="brand.100" href={el.website} isExternal>
                      {el.website}
                    </ChakraLink>
                  </Td>

                  <Td>{dayjs(el.createdAt).format('MMM D, YYYY h:mm A')}</Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th></Th>
                <Th>name</Th>
                <Th>website</Th>
                <Th>created at</Th>
              </Tr>
            </Tfoot>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default Sponsors;
