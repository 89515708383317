import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Select,
  Text,
  Link as ChakraLink,
  useToast,
  VStack,
  FormErrorMessage,
} from '@chakra-ui/react';
import axios from '../../../config/axios';
import { ReactEventHandler, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import useFetch from '../../../hooks/useFetch';
import Loader from '../../../components/loader';
import Error from '../../../components/error';
import { compressImage } from '../../../utils';

const EditMedia = ({
  media: md,
  onClose,
  editMedia,
}: {
  media: Media;
  onClose: () => void;
  editMedia: (media: Media) => void;
}) => {
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [mediaLoading, mediaError, media] = useFetch(`{
    mediaById(id: "${md.id}") {
      id
      mediaType
      fileType
      aspectRatio
      videoThumbnail
      src
      alt
      event {
        id
      }
    }
  }`);

  useEffect(() => {
    if (media) {
      setAspectRatio(media.mediaById.aspectRatio);
      setValue('fileType', media.mediaById.fileType);
    }
  }, [media, setValue]);

  const [aspectRatio, setAspectRatio] = useState(0);

  const fileType = watch('fileType', 'IMG');
  const imageSrc = watch('image', []);

  const isVideo = useMemo(
    () => fileType === 'VID' && media && media.mediaById.fileType === 'VID',
    [fileType, media]
  );

  const imageURL = useMemo(() => {
    if (imageSrc?.length > 0) {
      return URL.createObjectURL(imageSrc[0]);
    }
    return '';
  }, [imageSrc]);

  const onImageLoad: ReactEventHandler<HTMLImageElement> = (img) => {
    // @ts-ignore
    const height = img.target.offsetHeight;
    // @ts-ignore
    const width = img.target.offsetWidth;
    // @ts-ignore
    setAspectRatio(parseFloat(width / height).toFixed(4));
    // free memory
    URL.revokeObjectURL(imageURL);
  };

  const onSubmit = async (data: any) => {
    let cred = {
      ...data,
      event: md.event,
      aspectRatio: Number(aspectRatio),
    };
    delete cred.fileType;
    if (cred.mediaType === 'BASIC') delete cred.mediaType;
    let formData = new FormData();
    for (const [key, value] of Object.entries<any>(cred)) {
      if (typeof value === 'object') {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    }
    try {
      setLoading(true);
      const resData = await axios.put(
        `/event/media/update/${md.id}`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      const newUpdatedMedia = {
        ...resData.data.data.media,
        id: md.id,
      };
      editMedia(newUpdatedMedia);
      toast({
        status: 'success',
        title: 'media updated',
      });
      onClose();
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'failed to update media',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setLoading(false);
    }
  };

  if (mediaLoading) return <Loader />;
  if (mediaError) return <Error />;
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          {imageURL && (
            <img
              src={imageURL}
              alt=""
              onLoad={onImageLoad}
              style={{
                height: '100px',
                alignSelf: 'center',
                borderRadius: '20px',
              }}
            />
          )}
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="fileType" isRequired>
              <FormLabel>File Type</FormLabel>
              <Select
                {...register('fileType')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={media.mediaById.fileType}
              >
                <option value="IMG">Image</option>
                <option value="VID">Video</option>
              </Select>
            </FormControl>
            <FormControl id="fileType">
              <FormLabel>Media Type</FormLabel>
              <Select
                {...register('mediaType')}
                variant="filled"
                borderRadius="lg"
                disabled={isVideo}
                defaultValue={
                  media.mediaById.mediaType
                    ? media.mediaById.mediaType
                    : 'BASIC'
                }
              >
                <option value="BASIC">Basic</option>
                <option value="POSTER">Poster</option>
                <option value="BANNER">Banner</option>
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-end"
          >
            {isVideo && (
              <FormControl maxW="30%" isInvalid={errors.video}>
                <FormLabel>Video</FormLabel>
                <FormErrorMessage>
                  Video should be a maximum of 5MB
                </FormErrorMessage>
                <Text isTruncated mb="2" w="100%" fontSize="xs">
                  Current
                  <ChakraLink
                    opacity="0.8"
                    color="brand.100"
                    fontSize="sm"
                    isExternal
                    href={media.mediaById.src}
                  >
                    {media.mediaById.src}
                  </ChakraLink>
                </Text>
                <Input
                  {...register('video', {
                    validate: (value) => value && value[0].size < 5100000,
                  })}
                  variant="filled"
                  borderRadius="lg"
                  type="file"
                  accept="video/*"
                />
              </FormControl>
            )}
            <FormControl
              maxW={isVideo ? '30%' : '50%'}
              isInvalid={errors.image}
            >
              <FormLabel>{isVideo ? 'Video Thumbnail' : 'Image'}</FormLabel>
              <FormErrorMessage>
                Image should be a maximum of 2MB
              </FormErrorMessage>
              <Text isTruncated mb="2" w="100%" fontSize="xs">
                Current
                <ChakraLink
                  opacity="0.8"
                  color="brand.100"
                  fontSize="sm"
                  isExternal
                  href={compressImage(
                    media.mediaById.fileType === 'VID'
                      ? media.mediaById.videoThumbnail
                      : media.mediaById.src
                  )}
                >
                  {compressImage(
                    media.mediaById.fileType === 'VID'
                      ? media.mediaById.videoThumbnail
                      : media.mediaById.src
                  )}
                </ChakraLink>
              </Text>
              <Input
                {...register('image', {
                  validate: (value) => value && value[0].size < 2200000,
                })}
                variant="filled"
                borderRadius="lg"
                type="file"
                accept="image/*"
              />
            </FormControl>
            <FormControl id="aspectRatio" isReadOnly>
              <FormLabel>Aspect ratio</FormLabel>
              <Input
                {...register('aspectRatio')}
                variant="filled"
                borderRadius="lg"
                type="number"
                value={aspectRatio}
                disabled
              />
            </FormControl>
          </Stack>
          <FormControl id="alt" isRequired>
            <FormLabel>Media Description (alt)</FormLabel>
            <Input
              {...register('alt')}
              variant="filled"
              borderRadius="lg"
              required
              defaultValue={media.mediaById.alt}
            />
          </FormControl>
          <Button
            isLoading={loading}
            type="submit"
            variant="gradient"
            alignSelf="flex-end"
          >
            Update Media
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default EditMedia;
