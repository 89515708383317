import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  Stack,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Switch,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGlobalStoreContext } from '../../context';
import useArrayInput from '../../hooks/useArrayInput';
import useFetch from '../../hooks/useFetch';
import axios from '../../config/axios';
import { Link, useNavigate } from 'react-router-dom';

const CreateUser = () => {
  const { dispatch } = useGlobalStoreContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [rolesLoading, rolesError, rolesData] = useFetch(`{
    roles {
      id 
      name
    }
  }`);
  const { content, component } = useArrayInput({
    data: rolesData ? rolesData.roles : [],
    displayKey: 'name',
    uniqueKey: 'id',
    searchKey: 'name',
    label: 'Roles',
    loading: rolesLoading,
    error: rolesError,
    defaultData: [],
  });
  const { isOpen, onToggle } = useDisclosure();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'create user',
        description: 'create user account',
      },
    });
  }, [dispatch]);

  const onSubmit = async (data: any) => {
    let cred = { ...data };
    if (data.phone.length === 10) {
      cred.phone = '0' + data.phone;
    }
    cred = { ...cred, roles: content.map((el: any) => el.id) };
    try {
      setLoading(true);
      const data = await axios.post('/user/admin-create', cred);
      console.log(data);
      toast({
        status: 'success',
        title: 'user created',
      });
      navigate('/users');
    } catch {
      toast({
        status: 'error',
        title: 'failed to create user',
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/users">
            Users
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Create
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack direction={{ base: 'column', md: 'row' }}>
            <FormControl id="firstName" isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                {...register('firstName')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
            <FormControl id="lastName" isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                {...register('lastName')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
          </Stack>
          <Stack direction={{ base: 'column', md: 'row' }}>
            <FormControl
              isInvalid={Object.keys(errors).length > 0}
              id="phone"
              isRequired
            >
              <FormLabel>Phone</FormLabel>
              <InputGroup>
                <InputLeftAddon>+234</InputLeftAddon>
                <Input
                  {...register('phone', {
                    pattern: /^[0-9]*$/,
                    minLength: 10,
                    maxLength: 11,
                  })}
                  variant="filled"
                  borderRadius="lg"
                  required
                />
              </InputGroup>
              <FormErrorMessage>Invalid phone number</FormErrorMessage>
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                {...register('email')}
                variant="filled"
                borderRadius="lg"
                type="email"
                required
              />
            </FormControl>
          </Stack>
          <HStack alignItems="flex-end" spacing="5">
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  {...register('password')}
                  variant="filled"
                  borderRadius="lg"
                  type={isOpen ? 'text' : 'password'}
                  required
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={onToggle}>
                    {isOpen ? 'Hide' : 'Show'}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl id="isConfirmed">
              <FormLabel>is Confirmed</FormLabel>
              <Switch {...register('isConfirmed')} colorScheme="strict" />
            </FormControl>
          </HStack>
          {component}
          <Button
            isLoading={loading}
            type="submit"
            variant="gradient"
            alignSelf="flex-end"
          >
            Create User
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default CreateUser;
