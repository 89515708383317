import { Box, Stack, Icon, Input, Spinner, Tag, Text } from '@chakra-ui/react';
import { ChangeEvent, useEffect, useState } from 'react';
import { ImCross } from 'react-icons/im';
import { colorThemeArray } from '../config';

interface ArrayInputProps {
  content: any[];
  searchedContent: any[];
  keyword: string;
  handleChange: (e: any) => void;
  removeItem: (item: any) => void;
  addItem: (item: any) => void;
  displayKey: string;
  uniqueKey: string;
  label: string;
  error: boolean;
  loading: boolean;
}

const ArrayInput = ({
  content,
  searchedContent,
  keyword,
  handleChange,
  removeItem,
  addItem,
  displayKey,
  uniqueKey,
  label,
  error,
  loading,
}: ArrayInputProps) => {
  return (
    <Box>
      <Text>{label}</Text>
      <Stack
        p="2"
        borderRadius="lg"
        mt="3"
        bgColor="whiteAlpha.50"
        minH="10"
        w="100%"
        direction="row"
        alignItems="center"
        justifyContent="start"
        flexWrap="wrap"
        gap="2"
      >
        {content.map((el: any, index) => (
          <Tag
            key={el[uniqueKey]}
            minW="36"
            m="0 !important"
            colorScheme={colorThemeArray[index % colorThemeArray.length]}
          >
            <Icon
              cursor="pointer"
              boxSize="3"
              as={ImCross}
              mr="3"
              onClick={() => removeItem(el[uniqueKey])}
            />{' '}
            {el[displayKey].replace('_', ' ')}
          </Tag>
        ))}
        {loading ? (
          <Spinner />
        ) : error ? (
          <Text fontWeight="bold" color="red.400">
            error loading {label.toLowerCase()}
          </Text>
        ) : (
          <Box w="40" position="relative">
            <Input
              minH="10"
              variant="outline"
              value={keyword}
              onChange={handleChange}
            />
            <Box
              position="absolute"
              maxW="60"
              minW="40"
              maxH="230px"
              bottom="10"
              bgColor="background.300"
              shadow="dark-lg"
              borderRadius="lg"
              overflowY="scroll"
              zIndex="40"
            >
              {searchedContent
                .filter(
                  (el: any) =>
                    !content.some((el2) => el2[uniqueKey] === el[uniqueKey])
                )
                .map((el: any) => (
                  <Text
                    px="4"
                    py="2"
                    backgroundColor="background.100"
                    _hover={{
                      bgColor: 'background.200',
                    }}
                    cursor="pointer"
                    isTruncated
                    fontSize="14px"
                    key={el[uniqueKey]}
                    onClick={() => addItem(el)}
                  >
                    {el[displayKey].replaceAll('_', ' ')}
                  </Text>
                ))}
            </Box>
          </Box>
        )}
      </Stack>
    </Box>
  );
};

const useArrayInput = ({
  data,
  defaultData,
  searchKey,
  uniqueKey,
  displayKey,
  label,
  error,
  loading,
}: {
  label: string;
  defaultData: any[];
  data: any[];
  searchKey: string;
  uniqueKey: string;
  displayKey: string;
  error: boolean;
  loading: boolean;
}) => {
  const [searchedContent, setSearchedContent] = useState<any[]>([]);
  const [content, setContent] = useState<any[]>([]);
  const [keyword, setKeyword] = useState('');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setKeyword(query);
    if (query.length > 0) {
      setSearchedContent(
        data.filter((e: any) =>
          e[searchKey].toLowerCase().includes(query.toLowerCase())
        )
      );
    } else {
      setSearchedContent([]);
    }
  };

  useEffect(() => {
    if (defaultData.length > 0) {
      setContent(defaultData);
    }
  }, [defaultData]);

  const addItem = (item: any) => {
    setContent((state) => [...state, item]);
    setKeyword('');
    setSearchedContent([]);
  };

  const removeItem = (id: any) => {
    setContent((state) => [...state.filter((el) => el[uniqueKey] !== id)]);
  };

  const props: ArrayInputProps = {
    addItem,
    removeItem,
    content,
    searchedContent,
    handleChange,
    keyword,
    displayKey,
    uniqueKey,
    label,
    error,
    loading,
  };

  return {
    content,
    component: <ArrayInput {...props} />,
  };
};

export default useArrayInput;
