import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Box, HStack, Heading, Text } from '@chakra-ui/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import theme from '../../theme';
import Navbar from './navbar';
import Footer from './footer';
import Sidebar from './sidebar';
import { RiDashboardLine } from 'react-icons/ri';
import { GiBarracksTent } from 'react-icons/gi';
import { BsShop } from 'react-icons/bs';
import { useGlobalStoreContext } from '../../context';
import { useFetch } from '../../hooks';
import Loader from '../pageLoader';

const LINKS = [
  {
    name: 'dashboard',
    url: '/dashboard',
    icon: RiDashboardLine,
    links: [
      {
        name: 'overview',
        url: '/dashboard',
      },
      {
        name: 'jetron ticket',
        url: '/jetron-ticket',
      },
      {
        name: 'jetron mall',
        url: '/jetron-mall',
      },
      {
        name: 'users',
        url: '/users',
      },
      {
        name: 'psudo users',
        url: '/psudo-users',
      },
      {
        name: 'tokens',
        url: '/tokens',
      },
    ],
  },
  {
    name: 'event',
    url: '/events',
    icon: GiBarracksTent,
    links: [
      {
        name: 'events',
        url: '/events',
      },
      {
        name: 'sponsors',
        url: '/sponsors',
      },
      {
        name: 'guests',
        url: '/guests',
      },
      {
        name: 'ticket orders',
        url: '/ticket-orders',
      },
      {
        name: 'dynamic content',
        url: '/dynamic-content',
      },
      {
        name: 'blogs',
        url: '/blogs',
      },
    ],
  },
  {
    name: 'mall',
    url: '/',
    icon: BsShop,
    links: [],
  },
];

const Layout: FC = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, error, data] = useFetch(
    `
      {
        user {
          id
          firstName
          lastName
          email
          phone
          roles {
            name
          }
        }
      }
    `
  );

  const { dispatch, state } = useGlobalStoreContext();

  // get user information
  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else if (error) {
      // navigate('/login');
      setIsLoading(false);
    } else {
      const user = data.user;
      dispatch({
        type: 'LOGIN',
        payload: {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          email: user.email,
          isLoggedIn: true,
          isSocialAdmin: user.roles
            .map((el: any) => el.name)
            .includes('SOCIAL_MEDIA'),
          isAdmin: user.roles.map((el: any) => el.name).includes('ADMIN'),
          isTicketAdmin: user.roles
            .map((el: any) => el.name)
            .includes('TICKET_ADMIN'),
          isMallAdmin: user.roles
            .map((el: any) => el.name)
            .includes('MALL_ADMIN'),
          isSuperUser: user.roles
            .map((el: any) => el.name)
            .includes('SUPER_USER'),
        },
      });
      setIsLoading(false);
    }
  }, [data, dispatch, navigate, error, loading]);

  useEffect(() => {
    dispatch({
      type: 'SET_SIDEBAR',
      payload: false,
    });
  }, [location.pathname, dispatch]);
  return (
    <>
      <ChakraProvider resetCSS theme={extendTheme(theme)}>
        {(loading || isLoading) && <Loader />}
        <Navbar />
        <HStack alignItems="stretch" height="calc(100vh - 96px)">
          <Sidebar links={LINKS} />
          <Box
            w={
              location.pathname === '/login'
                ? '100%'
                : { base: '100%', lg: '80%' }
            }
            h="inherit"
            overflowY="scroll"
            m="0"
            p={{ base: 2, lg: 3 }}
            style={{
              marginInlineStart: '0',
            }}
          >
            {location.pathname !== '/login' && (
              <Box
                p={3}
                w="100%"
                borderRadius="lg"
                backgroundColor="background.100"
              >
                <Heading fontSize="xl" textTransform="uppercase">
                  {state.pageData.title}
                </Heading>
                <Text color="GrayText" isTruncated>
                  {state.pageData.description}
                </Text>
              </Box>
            )}
            <Box p={{ base: 3, lg: 6 }} minH="calc(100vh - 250px)">
              {children}
            </Box>
          </Box>
        </HStack>
        <Footer />
      </ChakraProvider>
    </>
  );
};

export default Layout;
