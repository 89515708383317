import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Select,
  Spinner,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { APP_DATA } from '../../config';
import axios from '../../config/axios';
import Axios from 'axios';
import { useGlobalStoreContext } from '../../context';
import useArrayInput from '../../hooks/useArrayInput';
import useGet from '../../hooks/useGet';
import { useFetch } from '../../hooks';
import { formatDefaultDate, sanitazeData } from '../../utils';
import Loader from '../../components/loader';
import Error from '../../components/error';
import DangerZone from '../../components/dangerZone';
import CreateTicket from './tickets/create';
import { Ticket } from '../../components/Ticket';
import CreateMedia from './medias/create';
import { Media } from '../../components/Media';

const EditEvent = () => {
  const { id } = useParams();
  const { dispatch } = useGlobalStoreContext();
  const cancelRef = useRef(null);
  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'edit event',
        description: "edit event's details",
      },
    });
  }, [dispatch]);
  const [eventLoading, eventError, event] = useFetch(`{
    eventById(id: "${id}") {
      name
      email
      score
      isExplicit
      isDisabled
      hideLocation
      isComingSoon
      host
      description
      date
      closingDate
      bank
      accountNumber
      commission
      instagram
      twitter
      facebook
      street
      lga
      state
      directions
      lng
      lat
      specialGuests {
        id
        stageName
      }
      sponsors {
        id
        name
      }
      category {
        id
      }
      tickets {
        id
        name
        price
        count
        isDefault
      }
      medias {
        id
        src
        alt
        fileType
        videoThumbnail
      }
    }
  }`);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [media, setMedia] = useState<Media[]>([]);
  const navigate = useNavigate();
  const toast = useToast();
  const { handleSubmit, register, watch } = useForm();
  const [userDetails, setUserDetails] = useState({
    name: '',
    loading: true,
    error: false,
  });
  const [loading, setLoading] = useState(false);
  const [specialGuestsLoading, specialGuestsError, specialGuestsData] =
    useFetch(`{
      specialGuests(keyword: "") {
        count
        data {
          id
          stageName
        }
      }
    }`);
  const { component: specialGuestsComponent, content: specialGuests } =
    useArrayInput({
      data:
        specialGuestsLoading || specialGuestsError
          ? []
          : specialGuestsData.specialGuests.data,
      defaultData:
        eventLoading || eventError ? [] : event.eventById.specialGuests,
      displayKey: 'stageName',
      error: specialGuestsError,
      label: 'Special Guests',
      loading: specialGuestsLoading,
      searchKey: 'stageName',
      uniqueKey: 'id',
    });
  const [sponsorsLoading, sponsorsError, sponsorsData] = useFetch(`{
      sponsors(keyword: "") {
        count
        data {
          id
          name
        }
      }
    }`);
  const { component: sponsorsComponent, content: sponsors } = useArrayInput({
    data: sponsorsLoading || sponsorsError ? [] : sponsorsData.sponsors.data,
    defaultData: eventLoading || eventError ? [] : event.eventById.sponsors,
    displayKey: 'name',
    error: sponsorsError,
    label: 'Sponsors',
    loading: sponsorsLoading,
    searchKey: 'name',
    uniqueKey: 'id',
  });

  const eventState = watch(
    'state',
    eventLoading || eventError ? 'Lagos' : event.eventById.state
  );
  const eventBank = watch('bank');
  const eventAZA = watch('accountNumber');

  const onSubmit = async (data: any) => {
    let cred = {
      ...data,
      sponsors: sponsors.map((el) => el.id),
      specialGuests: specialGuests.map((el) => el.id),
    };
    try {
      setLoading(true);
      await axios.put(`/event/update/${id}`, sanitazeData(cred));
      toast({
        status: 'success',
        title: 'event updated',
      });
      navigate('/events');
    } catch (err: any) {
      toast({
        status: 'error',
        description: err.response ? err.response.data.message : '',
        title: 'failed to update event',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let source = Axios.CancelToken.source();
    if (eventBank && eventAZA && eventAZA.length >= 8) {
      setUserDetails({
        error: false,
        name: '',
        loading: true,
      });
      axios
        .post(
          '/misc/banks/verify',
          {
            bank: eventBank,
            accountNumber: eventAZA,
          },
          {
            cancelToken: source.token,
          }
        )
        .then((data) => {
          setUserDetails({
            loading: false,
            name: data.data.data.data.account_name,
            error: false,
          });
        })
        .catch(() => {
          setUserDetails({
            loading: false,
            name: '',
            error: true,
          });
        });
    }
    return () => {
      source.cancel('Cancelling request');
    };
  }, [eventAZA, eventBank, event]);

  useEffect(() => {
    if (event) {
      setTickets(event.eventById.tickets);
      setMedia(event.eventById.medias);
    }
  }, [event]);

  const addTicket = (data: Ticket) => {
    setTickets((state) => [
      ...state.map((el) => ({
        ...el,
        isDefault:
          state.filter((el) => el.isDefault).length > 0 ? false : el.isDefault,
      })),
      data,
    ]);
  };

  const removeTicket = (id: string) => {
    setTickets((state) => state.filter((el) => el.id !== id));
  };

  const editTicket = (ticket: Ticket) => {
    setTickets((state) => [
      ...state.filter((el) => el.id !== ticket.id),
      ticket,
    ]);
  };

  const addMedia = (data: Media) => {
    setMedia((state) => [...state, data]);
  };

  const removeMedia = (id: string) => {
    setMedia((state) => state.filter((el) => el.id !== id));
  };

  const editMedia = (media: Media) => {
    setMedia((state) => [...state.filter((el) => el.id !== media.id), media]);
  };

  const [banksLoading, banksError, banks] = useGet('/misc/banks');
  const [categoriesLoading, categoriesError, categories] = useGet(
    '/misc/event/categories'
  );
  const [deleteEventLoading, setDeleteEventLoading] = useState(false);

  const {
    isOpen: dialogIsOpen,
    onOpen: dialogOpen,
    onClose: dialogOnClose,
  } = useDisclosure();

  const deleteEvent = async () => {
    try {
      setDeleteEventLoading(true);
      await axios.delete(`/event/delete/${id}`);
      navigate('/events');
      toast({
        status: 'success',
        title: 'event successfully deleted',
      });
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem deleting event',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setDeleteEventLoading(false);
    }
  };
  if (eventLoading) return <Loader />;
  if (eventError) return <Error />;
  return (
    <Box>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/events">
            Events
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Update
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={dialogIsOpen}
        onClose={dialogOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bgColor="background.100">
            <AlertDialogHeader>Delete Event</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this event?
            </AlertDialogBody>
            <AlertDialogFooter gap="3">
              <Button ref={cancelRef} onClick={dialogOnClose}>
                Cancel
              </Button>
              <Button
                isLoading={deleteEventLoading}
                colorScheme="red"
                onClick={deleteEvent}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="name" isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={event.eventById.name}
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                {...register('email')}
                variant="filled"
                type="email"
                borderRadius="lg"
                required
                defaultValue={event.eventById.email}
              />
            </FormControl>
            <FormControl id="host" isRequired>
              <FormLabel>Host</FormLabel>
              <Input
                {...register('host')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={event.eventById.host}
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="bank" isRequired>
              <FormLabel>Bank</FormLabel>
              {banksLoading ? (
                <Spinner />
              ) : banksError ? (
                <Text color="red.400">Error fetching banks</Text>
              ) : (
                <Select
                  variant="filled"
                  {...register('bank')}
                  defaultValue={event.eventById.bank}
                >
                  {banks.data.map((el: any) => (
                    <option key={el.code} value={el.code}>
                      {el.name}
                    </option>
                  ))}
                </Select>
              )}
            </FormControl>
            <FormControl id="accountNumber" isRequired>
              <FormLabel>Account Number</FormLabel>
              <Input
                {...register('accountNumber')}
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={event.eventById.accountNumber}
              />
              {eventAZA && eventAZA.length >= 8 && (
                <FormHelperText
                  color={
                    userDetails.loading
                      ? 'GrayText'
                      : userDetails.error
                      ? 'red.400'
                      : 'brand.100'
                  }
                  fontWeight={userDetails.name.length > 0 ? 'bold' : 'initial'}
                >
                  {userDetails.loading
                    ? 'Fetching bank details...'
                    : userDetails.error
                    ? 'Error fetching bank details'
                    : userDetails.name
                    ? userDetails.name
                    : ''}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl id="commission" isRequired>
              <FormLabel>Commission Rate</FormLabel>
              <Input
                {...register('commission')}
                variant="filled"
                borderRadius="lg"
                type="number"
                step="0.1"
                max="90"
                required
                defaultValue={event.eventById.commission}
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="instagram">
              <FormLabel>Instagram</FormLabel>
              <Input
                {...register('instagram')}
                variant="filled"
                borderRadius="lg"
                type="url"
                defaultValue={event.eventById.instagram}
              />
            </FormControl>
            <FormControl id="twitter">
              <FormLabel>Twitter</FormLabel>
              <Input
                {...register('twitter')}
                variant="filled"
                borderRadius="lg"
                type="url"
                defaultValue={event.eventById.twitter}
              />
            </FormControl>
            <FormControl id="facebook">
              <FormLabel>Facebook</FormLabel>
              <Input
                {...register('facebook')}
                variant="filled"
                borderRadius="lg"
                type="url"
                defaultValue={event.eventById.facebook}
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="score" isRequired>
              <FormLabel>Score</FormLabel>
              <Input
                {...register('score')}
                type="number"
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={event.eventById.score}
              />
            </FormControl>
            <FormControl id="isExplicit">
              <FormLabel>Is Explicit</FormLabel>
              <Switch
                {...register('isExplicit')}
                colorScheme="brand"
                borderRadius="lg"
                defaultChecked={event.eventById.isExplicit}
              />
            </FormControl>
            <FormControl id="isDisabled">
              <FormLabel>Is Disabled</FormLabel>
              <Switch
                {...register('isDisabled')}
                colorScheme="brand"
                borderRadius="lg"
                defaultChecked={event.eventById.isDisabled}
              />
            </FormControl>
            <FormControl id="hideLocation">
              <FormLabel>Hide Location</FormLabel>
              <Switch
                {...register('hideLocation')}
                colorScheme="brand"
                borderRadius="lg"
                defaultChecked={event.eventById.hideLocation}
              />
            </FormControl>
            <FormControl id="isComingSoon">
              <FormLabel>Is Coming Soon</FormLabel>
              <Switch
                {...register('isComingSoon')}
                colorScheme="brand"
                borderRadius="lg"
                defaultChecked={event.eventById.isComingSoon}
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="description" isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea
                variant="filled"
                {...register('description')}
                defaultValue={event.eventById.description}
              />
            </FormControl>
            <FormControl id="category" isRequired>
              <FormLabel>Category</FormLabel>
              {categoriesLoading ? (
                <Spinner />
              ) : categoriesError ? (
                <Text color="red.400">Error fetching categories</Text>
              ) : (
                <Select
                  variant="filled"
                  {...register('categoryId')}
                  textTransform="capitalize"
                  defaultValue={event.eventById.category.id}
                >
                  {categories.map((el: any) => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </Select>
              )}
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="date" isRequired>
              <FormLabel>Date</FormLabel>
              <Input
                {...register('date')}
                type="datetime-local"
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={formatDefaultDate(event.eventById.date)}
              />
            </FormControl>
            <FormControl id="closingDate" isRequired>
              <FormLabel>Closing Date</FormLabel>
              <Input
                {...register('closingDate')}
                type="datetime-local"
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={formatDefaultDate(event.eventById.closingDate)}
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="state" isRequired>
              <FormLabel>State</FormLabel>
              <Select
                variant="filled"
                {...register('state')}
                isRequired
                defaultValue={event.eventById.state}
              >
                {APP_DATA.states
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((el) => (
                    <option value={el.name} key={el.name}>
                      {el.name}
                    </option>
                  ))}
              </Select>
            </FormControl>
            <FormControl id="lga" isRequired>
              <FormLabel>Local Government Area</FormLabel>
              <Select
                variant="filled"
                {...register('lga')}
                defaultValue={event.eventById.lga}
              >
                {APP_DATA.states
                  .find((el) => el.name === eventState)
                  ?.lgas.map((lga) => (
                    <option value={lga} key={lga}>
                      {lga}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="street" isRequired>
              <FormLabel>Street</FormLabel>
              <Textarea
                variant="filled"
                {...register('street')}
                isRequired
                defaultValue={event.eventById.street}
              />
            </FormControl>
            <FormControl id="directions">
              <FormLabel>Directions</FormLabel>
              <Textarea
                variant="filled"
                {...register('directions')}
                defaultValue={event.eventById.directions}
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="lng" isRequired>
              <FormLabel>Longitude</FormLabel>
              <Input
                {...register('lng')}
                variant="filled"
                type="number"
                borderRadius="lg"
                step="0.0001"
                required
                defaultValue={event.eventById.lng}
              />
            </FormControl>
            <FormControl id="lat" isRequired>
              <FormLabel>Latitude</FormLabel>
              <Input
                {...register('lat')}
                variant="filled"
                type="number"
                step="0.0001"
                borderRadius="lg"
                required
                defaultValue={event.eventById.lat}
              />
            </FormControl>
          </Stack>
          {specialGuestsComponent}
          {sponsorsComponent}
          <Button
            isLoading={loading}
            type="submit"
            variant="gradient"
            alignSelf="flex-end"
          >
            Update Event
          </Button>
        </VStack>
      </form>
      <Divider my="10" />
      <Heading fontSize="lg" mb="5" color="GrayText">
        Tickets
      </Heading>
      <Grid
        mb="5"
        gap="4"
        templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
      >
        {tickets
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((el: Ticket) => (
            <GridItem key={el.id}>
              <Ticket
                // @ts-ignore
                ticket={{ ...el, event: { id: id ? id : '' } }}
                removeTicket={removeTicket}
                editTicket={editTicket}
              />
            </GridItem>
          ))}
      </Grid>
      <Heading fontSize="sm" mb="5" color="GrayText">
        Create Ticket
      </Heading>
      <CreateTicket event={id} addTicket={addTicket} />
      <Divider my="10" />
      <Heading fontSize="lg" mb="5" color="GrayText">
        Media
      </Heading>
      <Grid mb="5" gap="4" templateColumns="repeat(5, 1fr)" alignItems="center">
        {media
          .sort((a, b) => a.id.localeCompare(b.id))
          .map((el) => (
            <Media
              key={el.id}
              media={el}
              remove={removeMedia}
              editMedia={editMedia}
            />
          ))}
      </Grid>
      <Heading fontSize="sm" mb="5" color="GrayText">
        Create Media
      </Heading>
      <CreateMedia event={id ? id : ''} createMedia={addMedia} />
      <Divider my="10" />
      <DangerZone action={dialogOpen} text="Delete Event" />
    </Box>
  );
};

export default EditEvent;
