import { useEffect } from 'react';
import { useGlobalStoreContext } from '../context';

const Dashboard = () => {
  const { dispatch } = useGlobalStoreContext();
  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'dashboard',
        description: 'general overview of company',
      },
    });
  }, [dispatch]);
  return <>Hello</>;
};

export default Dashboard;
