import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGlobalStoreContext } from '../../../context';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Select,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { useFetch } from '../../../hooks';
import axios from '../../../config/axios';

const GenerateTicket = () => {
  const { dispatch } = useGlobalStoreContext();
  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'generate ticket order',
        description: 'generate ticket order for user',
      },
    });
  }, [dispatch]);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = async (data: any) => {
    const { email, firstName, lastName, ticket, quantity } = data;
    const cred = {
      email,
      firstName,
      lastName,
      ticket: { id: ticket, quantity },
    };
    try {
      setLoading(true);
      await axios.post('/event/ticket-order/generate', cred);
      toast({
        status: 'success',
        title: 'order successfully generated',
      });
      navigate('/ticket-orders');
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'problem generating order',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setLoading(false);
    }
  };

  const [ticketsLoading, ticketsError, tickets] = useFetch(`{
    tickets {
      data {
        id
        name
        count
        event {
          name
        }
      }
    }
  }`);

  return (
    <>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/ticket-orders">
            Ticket Orders
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Generate
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-end"
          >
            <FormControl id="name" isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                {...register('firstName')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                {...register('lastName')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                {...register('email')}
                variant="filled"
                type="email"
                borderRadius="lg"
                required
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-end"
          >
            <FormControl id="ticket" isRequired>
              <FormLabel>Ticket</FormLabel>
              {ticketsLoading ||
                (ticketsError && (
                  <FormHelperText
                    mb="2"
                    color={
                      ticketsLoading
                        ? 'initial'
                        : ticketsError
                        ? 'red.400'
                        : 'initial'
                    }
                  >
                    {ticketsLoading
                      ? 'fetching tickets'
                      : ticketsError
                      ? 'problem fetching tickets'
                      : ''}
                  </FormHelperText>
                ))}
              <Select
                {...register('ticket')}
                isDisabled={ticketsLoading || ticketsError}
                variant="filled"
                borderRadius="lg"
                required
                placeholder="Select Ticket"
                textTransform="uppercase"
              >
                {tickets &&
                  tickets.tickets.data
                    .sort((a: Ticket, b: Ticket) =>
                      a.event.name.localeCompare(b.event.name)
                    )
                    .map((el: Ticket) => (
                      <option key={el.id} value={el.id}>
                        {el?.event?.name} - {el.name}
                      </option>
                    ))}
              </Select>
            </FormControl>
            <FormControl id="quantity" isRequired isInvalid={errors.quantity}>
              <FormLabel>Quantity</FormLabel>
              <Input
                {...register('quantity')}
                variant="filled"
                borderRadius="lg"
                defaultValue={1}
                min={1}
                required
              />
            </FormControl>
          </Stack>
          <Button
            size="sm"
            alignSelf="flex-end"
            variant="gradient"
            type="submit"
            isLoading={loading}
          >
            Generate
          </Button>
        </VStack>
      </form>
    </>
  );
};

export default GenerateTicket;
