import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Spinner,
  Stack,
  Switch,
  Text,
  Textarea,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { APP_DATA } from '../../config';
import axios from '../../config/axios';
import Axios from 'axios';
import { useGlobalStoreContext } from '../../context';
import useArrayInput from '../../hooks/useArrayInput';
import useGet from '../../hooks/useGet';
import { useFetch } from '../../hooks';
import { sanitazeData } from '../../utils';

const CreateEvent = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { dispatch } = useGlobalStoreContext();
  const { handleSubmit, register, watch } = useForm();
  const [userDetails, setUserDetails] = useState({
    name: '',
    loading: true,
    error: false,
  });
  const [loading, setLoading] = useState(false);
  const [specialGuestsLoading, specialGuestsError, specialGuestsData] =
    useFetch(`{
      specialGuests(keyword: "") {
        count
        data {
          id
          stageName
        }
      }
    }`);
  const { component: specialGuestsComponent, content: specialGuests } =
    useArrayInput({
      data:
        specialGuestsLoading || specialGuestsError
          ? []
          : specialGuestsData.specialGuests.data,
      defaultData: [],
      displayKey: 'stageName',
      error: specialGuestsError,
      label: 'Special Guests',
      loading: specialGuestsLoading,
      searchKey: 'stageName',
      uniqueKey: 'id',
    });
  const [sponsorsLoading, sponsorsError, sponsorsData] = useFetch(`{
      sponsors(keyword: "") {
        count
        data {
          id
          name
        }
      }
    }`);
  const { component: sponsorsComponent, content: sponsors } = useArrayInput({
    data: sponsorsLoading || sponsorsError ? [] : sponsorsData.sponsors.data,
    defaultData: [],
    displayKey: 'name',
    error: sponsorsError,
    label: 'Sponsors',
    loading: sponsorsLoading,
    searchKey: 'name',
    uniqueKey: 'id',
  });

  const eventState = watch('state', 'Lagos');
  const eventBank = watch('bank');
  const eventAZA = watch('accountNumber');

  const onSubmit = async (data: any) => {
    let cred = {
      ...data,
      sponsors: sponsors.map((el) => el.id),
      specialGuests: specialGuests.map((el) => el.id),
    };
    try {
      setLoading(true);
      await axios.post('/event/create', sanitazeData(cred));
      toast({
        status: 'success',
        title: 'event created',
      });
      navigate('/events');
    } catch (err: any) {
      toast({
        status: 'error',
        title: 'failed to create event',
        description: err.response ? err.response.data.message : '',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch({
      type: 'SETUP_PAGE',
      payload: {
        title: 'create event',
        description: 'create event',
      },
    });
  }, [dispatch]);

  useEffect(() => {
    let source = Axios.CancelToken.source();
    if (eventBank && eventAZA && eventAZA.length >= 8) {
      setUserDetails({
        error: false,
        name: '',
        loading: true,
      });
      axios
        .post(
          '/misc/banks/verify',
          {
            bank: eventBank,
            accountNumber: eventAZA,
          },
          {
            cancelToken: source.token,
          }
        )
        .then((data) => {
          setUserDetails({
            loading: false,
            name: data.data.data.data.account_name,
            error: false,
          });
        })
        .catch(() => {
          setUserDetails({
            loading: false,
            name: '',
            error: true,
          });
        });
    }
    return () => {
      source.cancel('Cancelling request');
    };
  }, [eventAZA, eventBank]);

  const [banksLoading, banksError, banks] = useGet('/misc/banks');
  const [categoriesLoading, categoriesError, categories] = useGet(
    '/misc/event/categories'
  );

  return (
    <Box>
      <Breadcrumb color="GrayText" mb="4">
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/events">
            Events
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="">
            Create
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack w="100%" alignItems="stretch" spacing="10">
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="name" isRequired>
              <FormLabel>Name</FormLabel>
              <Input
                {...register('name')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                {...register('email')}
                variant="filled"
                type="email"
                borderRadius="lg"
                required
              />
            </FormControl>
            <FormControl id="host" isRequired>
              <FormLabel>Host</FormLabel>
              <Input
                {...register('host')}
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="bank" isRequired>
              <FormLabel>Bank</FormLabel>
              {banksLoading ? (
                <Spinner />
              ) : banksError ? (
                <Text color="red.400">Error fetching banks</Text>
              ) : (
                <Select variant="filled" {...register('bank')}>
                  {banks.data.map((el: any) => (
                    <option key={el.code} value={el.code}>
                      {el.name}
                    </option>
                  ))}
                </Select>
              )}
            </FormControl>
            <FormControl id="accountNumber" isRequired>
              <FormLabel>Account Number</FormLabel>
              <Input
                {...register('accountNumber')}
                variant="filled"
                borderRadius="lg"
                required
              />
              {eventAZA && eventAZA.length >= 8 && (
                <FormHelperText
                  color={
                    userDetails.loading
                      ? 'GrayText'
                      : userDetails.error
                      ? 'red.400'
                      : 'brand.100'
                  }
                  fontWeight={userDetails.name.length > 0 ? 'bold' : 'initial'}
                >
                  {userDetails.loading
                    ? 'Fetching bank details...'
                    : userDetails.error
                    ? 'Error fetching bank details'
                    : userDetails.name
                    ? userDetails.name
                    : ''}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl id="commission" isRequired>
              <FormLabel>Commission Rate</FormLabel>
              <Input
                {...register('commission')}
                variant="filled"
                borderRadius="lg"
                type="number"
                step="0.01"
                max="90"
                required
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="instagram">
              <FormLabel>Instagram</FormLabel>
              <Input
                {...register('instagram')}
                variant="filled"
                borderRadius="lg"
                type="url"
              />
            </FormControl>
            <FormControl id="twitter">
              <FormLabel>Twitter</FormLabel>
              <Input
                {...register('twitter')}
                variant="filled"
                borderRadius="lg"
                type="url"
              />
            </FormControl>
            <FormControl id="facebook">
              <FormLabel>Facebook</FormLabel>
              <Input
                {...register('facebook')}
                variant="filled"
                borderRadius="lg"
                type="url"
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="score" isRequired>
              <FormLabel>Score</FormLabel>
              <Input
                {...register('score')}
                type="number"
                variant="filled"
                borderRadius="lg"
                required
                defaultValue={0}
              />
            </FormControl>
            <FormControl id="isExplicit">
              <FormLabel>Is Explicit</FormLabel>
              <Switch
                {...register('isExplicit')}
                colorScheme="brand"
                borderRadius="lg"
              />
            </FormControl>
            <FormControl id="isDisabled">
              <FormLabel>Is Disabled</FormLabel>
              <Switch
                {...register('isDisabled')}
                colorScheme="brand"
                borderRadius="lg"
              />
            </FormControl>
            <FormControl id="hideLocation">
              <FormLabel>Hide Location</FormLabel>
              <Switch
                {...register('hideLocation')}
                colorScheme="brand"
                borderRadius="lg"
              />
            </FormControl>
            <FormControl id="isComingSoon">
              <FormLabel>Is Coming Soon</FormLabel>
              <Switch
                {...register('isComingSoon')}
                colorScheme="brand"
                borderRadius="lg"
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="description" isRequired>
              <FormLabel>Description</FormLabel>
              <Textarea variant="filled" {...register('description')} />
            </FormControl>
            <FormControl id="category" isRequired>
              <FormLabel>Category</FormLabel>
              {categoriesLoading ? (
                <Spinner />
              ) : categoriesError ? (
                <Text color="red.400">Error fetching categories</Text>
              ) : (
                <Select
                  variant="filled"
                  {...register('category')}
                  textTransform="capitalize"
                >
                  {categories.map((el: any) => (
                    <option key={el.id} value={el.id}>
                      {el.name}
                    </option>
                  ))}
                </Select>
              )}
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="date" isRequired>
              <FormLabel>Date</FormLabel>
              <Input
                {...register('date')}
                type="datetime-local"
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
            <FormControl id="scanCloseDate" isRequired>
              <FormLabel>Closing Date</FormLabel>
              <Input
                {...register('closingDate')}
                type="datetime-local"
                variant="filled"
                borderRadius="lg"
                required
              />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="state" isRequired>
              <FormLabel>State</FormLabel>
              <Select
                variant="filled"
                {...register('state')}
                defaultValue="Lagos"
                isRequired
              >
                {APP_DATA.states
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((el) => (
                    <option key={el.name}>{el.name}</option>
                  ))}
              </Select>
            </FormControl>
            <FormControl id="lga" isRequired>
              <FormLabel>Local Government Area</FormLabel>
              <Select variant="filled" {...register('lga')}>
                {APP_DATA.states
                  .find((el) => el.name === eventState)
                  ?.lgas.map((lga) => (
                    <option key={lga}>{lga}</option>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="street" isRequired>
              <FormLabel>Street</FormLabel>
              <Textarea variant="filled" {...register('street')} isRequired />
            </FormControl>
            <FormControl id="directions">
              <FormLabel>Directions</FormLabel>
              <Textarea variant="filled" {...register('directions')} />
            </FormControl>
          </Stack>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            alignItems="flex-start"
          >
            <FormControl id="lng" isRequired>
              <FormLabel>Longitude</FormLabel>
              <Input
                {...register('lng')}
                variant="filled"
                type="number"
                borderRadius="lg"
                step="0.0001"
                required
              />
            </FormControl>
            <FormControl id="lat" isRequired>
              <FormLabel>Latitude</FormLabel>
              <Input
                {...register('lat')}
                variant="filled"
                type="number"
                step="0.0001"
                borderRadius="lg"
                required
              />
            </FormControl>
          </Stack>
          {specialGuestsComponent}
          {sponsorsComponent}
          <Button
            isLoading={loading}
            type="submit"
            variant="gradient"
            alignSelf="flex-end"
          >
            Create Event
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default CreateEvent;
