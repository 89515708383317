import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ChangeEventHandler, useEffect, useState } from 'react';

interface useSearchInputProps {
  label: string;
  isRequired: boolean;
  placeholder: string;
  searchOnChange: ChangeEventHandler<HTMLInputElement>;
  searchValue: string;
  searchValueKeys: string[];
  setSearchValue: (text: string) => void;
  loading: boolean;
  error: boolean;
  elements: any;
  disabled?: boolean;
  defaultValue: any;
}

const useSearchInput = ({
  label,
  isRequired,
  placeholder,
  searchOnChange,
  searchValue,
  loading,
  error,
  elements,
  setSearchValue,
  searchValueKeys,
  disabled,
  defaultValue,
}: useSearchInputProps) => {
  const [value, setValue] = useState<string | null>(null);
  const [focused, setFocused] = useState(false);

  const handleOutsideClick = () => {
    setValue('');
    setSearchValue('');
    setFocused(false);
  };

  useEffect(() => {
    setSearchValue(searchValueKeys.map((i) => defaultValue[i]).join(' '));
    setValue(defaultValue.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const component = (
    <FormControl position="relative" isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <Input
        borderRadius="lg"
        mb="2"
        variant="filled"
        placeholder={placeholder}
        value={searchValue}
        isRequired={false}
        borderColor="background.100"
        onChange={searchOnChange}
        onFocus={() => setFocused(true)}
        disabled={disabled}
      />
      {loading ? (
        <HStack px="2" color="GrayText">
          <Text textTransform="capitalize">Fetching {label}s</Text>
          <Spinner size="sm" />
        </HStack>
      ) : error ? (
        <HStack px="2" color="red.400">
          <Text textTransform="capitalize">Problem fetching {label}s</Text>
        </HStack>
      ) : (
        elements &&
        focused &&
        !disabled && (
          <VStack
            alignItems="center"
            position="absolute"
            zIndex={20}
            top="105%"
            bgColor="background.400"
            w="100%"
            borderRadius="lg"
            maxH="200px"
            overflow="scroll"
            shadow="lg"
          >
            {!isRequired && (
              <Box
                p={2.5}
                _hover={{
                  bgColor: 'background.100',
                }}
                cursor="pointer"
                w="100%"
                textTransform="capitalize"
                onClick={handleOutsideClick}
              >
                None
              </Box>
            )}
            {elements.map((el: any) => (
              <Box
                p={2.5}
                key={el.id}
                _hover={{
                  bgColor: 'background.100',
                }}
                cursor="pointer"
                w="100%"
                textTransform="capitalize"
                onClick={() => {
                  setValue(el.id);
                  setSearchValue(
                    `${searchValueKeys.map((i) => el[i]).join(' ')}`
                  );
                  setFocused(false);
                }}
              >
                {searchValueKeys.map((i) => el[i]).join(' ')}
              </Box>
            ))}
          </VStack>
        )
      )}
    </FormControl>
  );
  return {
    value,
    component,
  };
};

export default useSearchInput;
